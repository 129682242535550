import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCurrentSession } from "@milkmoovement/common_cognito_package";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useCommonSelector } from "../hooks";
import { selectUser } from "../store";

interface IProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<IProps> = ({ children }) => {
  const [session, setSession] = useState<
    CognitoUserSession | false | undefined
  >(undefined);
  const [waitingForAuth, setWaitingForAuth] = useState(true);
  const user = useCommonSelector(selectUser);

  useEffect(() => {
    setWaitingForAuth(true);
    (async () => {
      try {
        const session = await getCurrentSession();
        if (!session || !session.isValid()) {
          setSession(undefined);
        } else {
          setSession(session);
        }
      } catch (err) {
        setSession(undefined);
      } finally {
        setWaitingForAuth(false);
      }
    })();
  }, [user]);

  if (waitingForAuth) return <></>;
  if (session) return <>{children}</>;

  return <Navigate to="/login" replace />;
};

export default PrivateRoute;
