import { CircularProgress, styled } from "@mui/material";

const StyledLoadingOverlay = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 10,
}));

const LoadingOverlay: React.FC = () => {
  return (
    <StyledLoadingOverlay>
      <CircularProgress />
    </StyledLoadingOverlay>
  );
};

export default LoadingOverlay;
