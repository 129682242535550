import { ICartItem, IDeliveryInfo, IOrder, orderApi } from "../../../index";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommonState } from "../../store";

export interface IOrderSlice {
  loading: boolean;
  error?: string | null;
  order?: IOrder;
  runningVerification: boolean;
  verified?: boolean;
  inReview?: string;
}

const initialState: IOrderSlice = {
  loading: false,
  runningVerification: false,
};

interface IFetchParams {
  orderId: string;
  vendorId: string;
  userId: string;
}

export const fetchOrder = createAsyncThunk(
  "order/fetchOrder",
  async (fetchParams: IFetchParams) => {
    const { orderId, vendorId, userId } = fetchParams;
    const [order, orderItems, trackingRecord, deliveryDetails] =
      await Promise.all([
        await orderApi.getOrder(orderId, vendorId, userId),
        await orderApi.getOrderItems(orderId, vendorId, userId),
        await orderApi.getOrderTrackingRecords(orderId, vendorId, userId),
        await orderApi.getOrderDeliveryDetails(orderId),
        // TODO bert how to get vendor name needed for show in review panel?  either query vendorApi or get from vendors in store?
      ]);
    return {
      ...order,
      items: orderItems,
      trackingRecord,
      deliveryDetails,
    };
  }
);

interface ICreateParams {
  cartId: string;
  vendorId: string;
  userId: string;
}

export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (createParams: ICreateParams) => {
    const { cartId, vendorId, userId } = createParams;
    const response = await orderApi.postOrder(vendorId, userId, cartId);

    if (response.orderId)
      return await orderApi.getOrder(response.orderId, vendorId, userId);
    else throw new Error("Failed to create order");
  }
);

interface IVerifyParams {
  vendorId: string;
  userId: string;
  body: {
    deliveryInfo: IDeliveryInfo;
    items: ICartItem[];
  };
}

export const verifyOrder = createAsyncThunk(
  "order/verifyOrder",
  async (verifyParams: IVerifyParams) => {
    const { vendorId, userId, body } = verifyParams;
    return await orderApi.verifyOrder(vendorId, userId, body);
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setInReview: (state, { payload }: { payload: string }) => {
      state.inReview = payload;
    },
    removeInReview: (state) => {
      delete state.inReview;
    },
    clearOrder: (state) => {
      delete state.order;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrder.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchOrder.rejected, (state) => {
      state.loading = false;
      state.error = "Cannot Load Order";
    });
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.order = action.payload;
    });
    builder.addCase(createOrder.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createOrder.rejected, (state) => {
      state.loading = false;
      state.error =
        "Error connecting to the vendor. Please try at a later time.";
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.order = action.payload;
    });
    builder.addCase(verifyOrder.pending, (state) => {
      state.runningVerification = true;
      state.verified = false;
      state.error = undefined;
    });
    builder.addCase(verifyOrder.rejected, (state) => {
      state.runningVerification = false;
      state.verified = false;
      state.error = "Unable to connect to the Vendor.";
    });
    builder.addCase(verifyOrder.fulfilled, (state, action) => {
      state.runningVerification = false;
      state.error = undefined; // Fixes issues with douple dispatch on webpack devserver

      if (
        action.payload.lastStep?.error === undefined &&
        action.payload.lastStep?.output?.error === undefined
      ) {
        state.verified = true;
        return;
      }

      if (action.payload.lastStep?.output?.error?.message)
        state.error = action.payload.lastStep.output.error.message;
      else state.error = "Unable to connect to the vendor.";

      state.verified = false;
    });
  },
});

export const { setInReview, removeInReview, clearOrder } = orderSlice.actions;

export const selectOrder = ({ order }: CommonState) => order;

export default orderSlice.reducer;
