import axios from "../../network/axios";

const getOrderItems = async (
  orderId: string,
  vendorId: string,
  userId: string
) => {
  try {
    const { items } = (
      await axios.get(
        `${process.env.REACT_APP_ORDER_API_URL}/orders/${orderId}/items?vendorId=${vendorId}&userId=${userId}`
      )
    ).data;

    return items;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return [];
    }
    throw new Error("Unable to fetch order items");
  }
};

export default getOrderItems;
