import { lazy } from "react";
import { IRoutes } from "@milkmoovement/mm_shop_common_web_component";
import ConnectedVendorSelect from "../pages/shop/pages/connectedVendorSelect";
const Product = lazy(() => import("../pages/shop/pages/product"));

const CheckoutProducts = lazy(() => import("../pages/checkout/pages/products"));
const CheckoutDeliveryInfo = lazy(
  () => import("../pages/checkout/pages/deliveryInfo")
);
const CheckoutOrderSummary = lazy(
  () => import("../pages/checkout/pages/orderSummary")
);
const CheckoutOrderPlaced = lazy(
  () => import("../pages/checkout/pages/orderPlaced")
);
const Orders = lazy(() => import("../pages/orders"));
const Order = lazy(() => import("../pages/orders/order"));
const PickupsAndLabs = lazy(() => import("../pages/pickups-and-labs"));
const VendorConnections = lazy(() => import("../pages/vendor-connections"));
const FluidPlaceholder = lazy(
  () => import("../pages/processed-fluids/fluid-placeholder")
);
const SubUserPlaceholder = lazy(
  () => import("../pages/manage-sub-users/sub-user-placeholder")
);
const SettingPlaceholder = lazy(
  () => import("../pages/settings/setting-placeholder")
);
const VendorSupport = lazy(() => import("../pages/vendor-support"));

const routes: IRoutes = [
  {
    path: "/",
    element: <ConnectedVendorSelect />,
  },
  {
    path: "/vendors/:vendorId",
    element: <ConnectedVendorSelect />,
  },
  {
    path: "/vendors/:vendorId/products",
    element: <Product />,
  },
  {
    path: "/vendors/:vendorId/carts/:cartId/checkout/products",
    element: <CheckoutProducts />,
  },
  {
    path: "/vendors/:vendorId/carts/:cartId/checkout/deliveryInfo",
    element: <CheckoutDeliveryInfo />,
  },
  {
    path: "/vendors/:vendorId/carts/:cartId/checkout/orderSummary",
    element: <CheckoutOrderSummary />,
  },
  {
    path: "/vendors/:vendorId/carts/:cartId/checkout/orderPlaced",
    element: <CheckoutOrderPlaced />,
  },
  {
    path: "/orders",
    element: <Orders />,
  },
  {
    path: "/orders/:id",
    element: <Order />,
  },
  {
    path: "/pickups-and-labs",
    element: <PickupsAndLabs />,
  },
  {
    path: "/vendor-connections",
    element: <VendorConnections />,
  },
  {
    path: "/fluid-placeholder",
    element: <FluidPlaceholder />,
  },
  {
    path: "/sub-user-placeholder",
    element: <SubUserPlaceholder />,
  },
  {
    path: "/setting-placeholder",
    element: <SettingPlaceholder />,
  },
  {
    path: "/vendor-support",
    element: <VendorSupport />,
  },
];

export default routes;
