import { ICartItem, IDeliveryInfo } from "../../interfaces";
import vendors from "../vendors";
import { VENDOR_PROCESS_FUNCTIONS } from "../vendors/vendorProcessEnumerations";

const verifyOrder = async (
  vendorId: string,
  userId: string,
  body: {
    deliveryInfo: IDeliveryInfo;
    items: ICartItem[];
  }
) => {
  return await vendors.runVendorProcess(
    VENDOR_PROCESS_FUNCTIONS.VALIDATE_CART,
    vendorId,
    userId,
    body
  );
};

export default verifyOrder;
