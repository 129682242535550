import axios from "../../network/axios";

const getOrder = async (orderId: string, vendorId: string, userId: string) => {
  try {
    const order = (
      await axios.get(
        `${process.env.REACT_APP_ORDER_API_URL}/orders/${orderId}?vendorId=${vendorId}&userId=${userId}`
      )
    ).data;

    return order;
  } catch {
    throw new Error("Unable to fetch order");
  }
};

export default getOrder;
