import { Moment } from "moment";
import { CircularProgress, styled, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const DatePickerTextField = styled(TextField)(() => ({
  width: "100%",
}));

const DatePicker = ({
  value,
  disabled,
  loading,
  onChange,
  shouldDisableDate,
}: {
  value: Moment | null;
  disabled?: boolean;
  loading?: boolean;
  onChange: (date: Moment | null) => void;
  shouldDisableDate: (date: Moment) => boolean;
}) => {
  if (loading)
    return (
      <DatePickerTextField
        inputProps={{
          placeholder: "Select date",
        }}
        InputProps={{
          endAdornment: <CircularProgress />,
        }}
        disabled
      />
    );
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        inputFormat="MM/DD/YYYY"
        value={value}
        onChange={onChange}
        disabled={!!disabled}
        shouldDisableDate={shouldDisableDate}
        renderInput={(params) => (
          <DatePickerTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "Select date",
              "aria-label": "date-picker-input",
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
