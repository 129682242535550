/* eslint-disable @typescript-eslint/no-explicit-any */
import { joiResolver } from "@hookform/resolvers/joi";
import { Box, Stack, styled } from "@mui/material";
import { useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { LoadingOverlay } from "../../components";
import Logo from "../../components/logo";
import Joi from "joi";
import {
  authenticateUser,
  clearCognitoCache,
  sendMFACode,
  signOut,
} from "../../cognito";
import { ClientMetadata, CognitoUser } from "amazon-cognito-identity-js";
import {
  StyledAlert,
  StyledButton,
  StyledFormControl,
  StyledHeader,
  StyledInputLabel,
  StyledOutlinedInput,
} from "../styles";
import QR from "qrcode";

const StyledPage = styled("div")(() => ({
  display: "flex",
  // backgroundColor: theme.palette.secondary.dark,
  backgroundColor: "#151B29",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "100%",
  minHeight: "100vh",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "15px !important",
  padding: "30px",
  backgroundColor: theme.palette.background.default,
  borderRadius: "10px",
  gap: "20px",
  maxWidth: "600px",
  width: "100%",
}));

type IFormInputs = {
  username: string;
  password: string;
  newMFACode: string;
  mfaCode?: string;
};

const formSchema = Joi.object({
  username: Joi.string().min(4).required(),
  password: Joi.string().min(8).required(),
  mfaCode: Joi.string()
    .length(6)
    .when("MFARequired", {
      is: true,
      then: Joi.required(),
      otherwise: Joi.optional().allow(""),
    }),
  newMFACode: Joi.string().length(6).required(),
});

interface ILoginProps {
  onSucessfulLogin: () => Promise<void>;
  onNavigateBack: () => void;
  metadata?: ClientMetadata;
}

const Login: React.FC<ILoginProps> = ({
  onSucessfulLogin,
  onNavigateBack,
  metadata,
}) => {
  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [displayQRCode, setDisplayQRCode] = useState(false);
  const [MFARequired, setMFARequired] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const defaultUsername =
    localStorage.getItem("mm.login.currentUsername") || "";

  const { register, handleSubmit, formState, watch } = useForm<IFormInputs>({
    resolver: joiResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      username: defaultUsername,
      mfaCode: "",
    },
  });
  const { errors, isValid } = formState;

  const username = watch("username");
  const password = watch("password");
  const mfaCode = watch("mfaCode");

  const onLogin = async () => {
    try {
      setIsLoggingIn(true);
      clearCognitoCache();
      localStorage.removeItem("mm.login.appClientId");

      let loginUser: CognitoUser;
      let loginMFARequired: boolean;

      if (user && mfaCode && mfaCode.length > 0) {
        ({ user: loginUser, MFARequired: loginMFARequired } = await sendMFACode(
          user,
          username,
          mfaCode
        ));
      } else {
        ({ user: loginUser, MFARequired: loginMFARequired } =
          await authenticateUser(username, password, metadata, user));
      }

      if (loginMFARequired) {
        setMFARequired(true);
      } else {
        await new Promise<void>((resolve, reject) => {
          loginUser.associateSoftwareToken({
            onFailure: () => {
              reject("Error associating software token");
            },
            associateSecretCode: async (secretCode) => {
              const name = "Milk Moovement";
              const uri = `otpauth://totp/${decodeURI(
                name
              )}?secret=${secretCode}`;

              const image = await QR.toDataURL(uri);
              setQrCodeImage(image);
              setDisplayQRCode(true);
              setMFARequired(false);
              resolve();
            },
          });
        });
      }
      setUser(loginUser);
      setAlert("");
    } catch (err: any) {
      signOut();
      setUser(undefined);
      resetState();
      if (err.code === "UserNotFoundException")
        setAlert("Account not found, please contact support.");
      else if (err.code === "NotAuthorizedException")
        setAlert("Invalid credentials, please try again.");
      else if (err.code === "CodeMismatchException")
        setAlert("Invalid credentials, please try again.");
      else if (err.code === "PasswordResetRequiredException")
        setAlert("Error logging in, please reset your password.");
      else
        setAlert(
          "Error while attempting to log in, please try again or contact support."
        );
    } finally {
      setIsLoggingIn(false);
    }
  };

  const onSubmit = async ({ newMFACode }: IFormInputs) => {
    try {
      setIsLoggingIn(true);
      if (!user || !newMFACode) {
        throw new Error("Error validating code.");
      }
      await new Promise((resolve, reject) => {
        user.verifySoftwareToken(newMFACode, "MY_TOTP_DEVICE", {
          onSuccess: resolve,
          onFailure: reject,
        });
      });
      resetState();
      setSuccess(true);

      await new Promise((resolve, reject) => {
        user.setUserMfaPreference(
          null,
          { Enabled: true, PreferredMfa: true },
          (err, result) => {
            if (err) {
              reject(err);
            }
            resolve(result);
          }
        );
      });

      await new Promise((resolve, reject) => {
        user.forgetDevice({
          onSuccess: resolve,
          onFailure: reject,
        });
      });
      await onSucessfulLogin();
    } catch (err: any) {
      if (err.message === "Code mismatch")
        setAlert("Incorrect verification code, please try again.");
      else {
        signOut();
        resetState();
        setUser(undefined);
        setAlert(
          "Error while attempting to log in, please try again or contact support."
        );
      }
    } finally {
      setIsLoggingIn(false);
    }
  };
  const resetState = () => {
    setAlert("");
    setSuccess(false);
    setDisplayQRCode(false);
    setQrCodeImage("");
    setMFARequired(false);
    setIsLoggingIn(false);
  };

  const onInvalid = (errors: FieldErrors) => console.error(errors);

  return (
    <>
      {isLoggingIn && <LoadingOverlay />}
      <Logo />
      <StyledHeader>Set up Multi-factor Authentication</StyledHeader>
      {alert && (
        <StyledAlert
          sx={{ width: "100%", maxWidth: 400 }}
          data-testid="alert"
          severity="error"
        >
          {alert}
        </StyledAlert>
      )}
      {success && (
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%", maxWidth: 400 }}
        >
          <StyledAlert severity="info">
            Success! Now logging you in.
          </StyledAlert>
        </Box>
      )}
      {displayQRCode && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          component="form"
          sx={{ width: "100%", maxWidth: 400 }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <StyledAlert severity="info" data-testid="mfa-instructions">
            Please scan the QR code with an authenticator app and enter the
            verification code below.
          </StyledAlert>
          <Box display="flex" justifyContent="center">
            <img src={qrCodeImage} alt="QR Code" height="250" width="250" />
          </Box>
          <StyledFormControl>
            <StyledInputLabel error={!!errors.newMFACode}>
              Verification Code *
            </StyledInputLabel>
            <StyledOutlinedInput
              {...register("newMFACode")}
              label="Verification Code *"
              inputProps={{
                "data-testid": "input-newMFACode",
              }}
              error={!!errors.newMFACode}
            />
          </StyledFormControl>
          <StyledButton
            sx={{ display: displayQRCode ? "inherit" : "none" }}
            type="submit"
            variant="contained"
            data-testid="submit-button"
            disabled={!isValid || isLoggingIn}
          >
            Submit
          </StyledButton>
        </Box>
      )}
      {MFARequired && !displayQRCode && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          component="form"
          sx={{ width: "100%", maxWidth: 400 }}
        >
          <StyledAlert severity="info">
            Please open your registered authenticator app and enter the
            verification code below.
          </StyledAlert>
          <StyledFormControl>
            <StyledInputLabel error={!!errors.mfaCode}>
              Verification Code *
            </StyledInputLabel>
            <StyledOutlinedInput
              {...register("mfaCode")}
              label="Verification Code *"
              inputProps={{
                "data-testid": "input-mfaCode",
              }}
              disabled={isLoggingIn}
              error={!!errors.mfaCode}
            />
          </StyledFormControl>
        </Box>
      )}
      {!displayQRCode && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          component="form"
          sx={{ width: "100%", maxWidth: 400 }}
        >
          {!MFARequired && (
            <>
              <StyledAlert severity="info">
                Please log in to your acccount to continue
              </StyledAlert>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.username}>
                  Username/Email *
                </StyledInputLabel>
                <StyledOutlinedInput
                  {...register("username")}
                  label="Username/Email *"
                  inputProps={{
                    "data-testid": "input-username",
                  }}
                  disabled={success}
                  error={!!errors.username}
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.password}>
                  Password *
                </StyledInputLabel>
                <StyledOutlinedInput
                  type="password"
                  {...register("password")}
                  label="Password *"
                  inputProps={{
                    "data-testid": "input-password",
                  }}
                  disabled={success}
                  error={!!errors.password}
                />
              </StyledFormControl>
            </>
          )}
          <StyledButton
            variant="contained"
            type="button"
            disabled={
              username === "" ||
              password === "" ||
              (MFARequired && mfaCode === "") ||
              !!errors.mfaCode ||
              !!errors.password ||
              !!errors.username ||
              isLoggingIn ||
              success
            }
            onClick={() => onLogin()}
          >
            Continue
          </StyledButton>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        component="form"
        sx={{ width: "100%", maxWidth: 400 }}
      >
        <StyledButton
          variant="outlined"
          onClick={() => {
            onNavigateBack();
          }}
        >
          Back
        </StyledButton>
      </Box>
    </>
  );
};

export default (props: ILoginProps) => {
  return (
    <StyledPage>
      <Stack
        spacing={2}
        alignItems="center"
        sx={{ width: "100%", maxWidth: "600px", marginX: "15px" }}
      >
        <StyledBox>
          <Login {...props} />
        </StyledBox>
      </Stack>
    </StyledPage>
  );
};
