import {
  IProduct,
  vendorApi,
} from "@milkmoovement/mm_shop_common_web_component";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { CommonState } from ".";
import { clearProductValidationErrors } from "./vendorSelectionsSlice";

export interface IProductsSlice {
  loading?: boolean;
  error?: { message: string; code: number } | null;
  products: IProduct[];
}

const initialState: IProductsSlice = {
  loading: false,
  products: [],
};

interface IFetchParams {
  vendorId: string;
  userId: string;
  variables: Record<string, string>;
}

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (fetchParams: IFetchParams, thunkAPI) => {
    const { vendorId, userId, variables } = fetchParams;
    const response = await vendorApi.getProducts(vendorId, userId, variables);
    thunkAPI.dispatch(clearProductValidationErrors());
    const products: IProduct[] = response.products
      .map((product: IProduct) => {
        return {
          ...product,
          minQuantity:
            product.isPallet &&
            product.minQuantity < Number(product.perPalletCount)
              ? Number(product.perPalletCount) * product.minQuantity
              : product.minQuantity,
        };
      })
      .sort((a: IProduct, b: IProduct) =>
        a.productName.localeCompare(b.productName)
      );
    return products;
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearProducts: (state) => {
      state.loading = false;
      state.error = null;
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.products = [];
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      // parse axios error to get error code
      const code = parseInt(
        action.error.message?.split(" ").slice(-1)[0] ?? ""
      );
      state.loading = false;
      state.error = {
        code: !isNaN(code) ? code : 500,
        message: "Cannot Load Products",
      };
      state.products = [];
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

const selectSelf = (state: CommonState) => state.products;
export const { clearProducts } = productsSlice.actions;
export const selectProductsSlice = createSelector(selectSelf, (state) => state);
export const selectProductsIndexed = createSelector(
  selectSelf,
  ({ products }) => {
    const result: { [key: string]: IProduct } = {};
    products.forEach((product) => {
      result[product.productCode] = product;
    });
    return result;
  }
);

export const selectProductsLoading = ({ products }: CommonState) =>
  products.loading;

export default productsSlice.reducer;
