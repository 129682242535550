import { styled } from "@mui/material/styles";
import IConnectedVendor from "../../../interfaces/IConnectedVendor";
import ConnectedVendorBox from "./ConnectedVendorBox";

const Root = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "25px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "200px",
    gridTemplateColumns: "1fr",
    justifyContent: "center",
  },
  [theme.breakpoints.up("sm")]: {
    maxWidth: "600px",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
}));

interface IProps {
  connectedVendors: IConnectedVendor[];
  selectedVendor: IConnectedVendor | null;
  onSelectVendor: (vendor: IConnectedVendor) => void;
}

const ConnectedVendorGrid: React.FC<IProps> = ({
  connectedVendors,
  selectedVendor,
  onSelectVendor,
}) => (
  <Root>
    {connectedVendors?.map((vendor) => {
      const isSelected = vendor.name === selectedVendor?.name;
      return (
        <ConnectedVendorBox
          key={vendor.name}
          vendor={vendor}
          isSelected={isSelected}
          onSelectVendor={onSelectVendor}
        />
      );
    })}
  </Root>
);

export default ConnectedVendorGrid;
