import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ISettings } from "../../../settings";
import LightLogo from "../../../assets/logos/LightLogo";

interface IStyledHeaderProps {
  settings: ISettings;
}

const StyledHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "settings",
})<IStyledHeaderProps>(({ theme, settings }) => ({
  position: "sticky",
  top: 0,
  height: settings.toolbarHeight,
  width: "100%",
  backgroundColor: theme.palette.secondary.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  borderBottom: "1px solid black",
}));

interface IProps {
  settings: ISettings;
}

const Header: React.FC<IProps> = ({ settings }) => (
  <StyledHeader settings={settings}>
    <Box
      component="div"
      sx={{ width: "50%" }}
      display="flex"
      alignItems="center"
    >
      <LightLogo />
    </Box>
  </StyledHeader>
);

export default Header;
