import { Routes, Route, useNavigate } from "react-router-dom";
import { IRouter } from "../interfaces";
import Layout from "../layout";
import settings from "../settings";
import NotFound from "../pages/not-found";
import PrivateRoute from "./PrivateRoute";
import {
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
  SignupConfirmed,
} from "@milkmoovement/common_cognito_package";

const Router: React.FC<IRouter> = ({ navigation, routes }) => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Login
            onSucessfulLogin={async () => navigate("/")}
            onNavigateRegister={() => navigate("/register")}
            onNavigateForgotPassword={() => navigate("/forgot-password")}
          />
        }
      />
      <Route
        path="/register"
        element={<Register onNavigateBack={() => navigate("/login")} />}
      />
      <Route
        path="/forgot-password"
        element={<ForgotPassword onNavigateBack={() => navigate("/login")} />}
      />
      <Route
        path="/reset-password"
        element={<ResetPassword onNavigateBack={() => navigate("/login")} />}
      />
      <Route
        path="/signup-confirmed"
        element={<SignupConfirmed onNavigateLogin={() => navigate("/login")} />}
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout navigation={navigation} settings={settings} />
          </PrivateRoute>
        }
      >
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
