import {
  CircularProgress,
  Stack,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";

interface IProps {
  minValue: number;
  value: number;
  loading?: boolean;
  error?: boolean;
  onChange: (newValue: number) => void;
}

const QuantityInput: React.FC<IProps> = ({
  value,
  minValue,
  loading,
  error,
  onChange,
}) => {
  const getSelectValues = useCallback(
    () =>
      Array(5)
        .fill(0)
        .map((_, idx) => minValue * (idx + 1)),
    [minValue]
  );
  const [isInput, setIsInput] = useState<boolean>(
    !getSelectValues().includes(value)
  );
  const [textFieldValue, setTextFieldValue] = useState<string | number>(value);
  const [displayUpdateButton, setDisplayUpdateButton] = useState(false);

  useEffect(() => {
    const isInput = !getSelectValues().includes(value);
    setIsInput(isInput);
    if (isInput) {
      setTextFieldValue(value);
    }
  }, [value, getSelectValues]);

  const handleSelectChange = (value: number | string) => {
    switch (typeof value) {
      case "string":
        return setIsInput(true);
      case "number":
        onChange(value);
        return setTextFieldValue(value);
    }
  };

  const handleTextFieldChange = (value: number | string) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    setDisplayUpdateButton(true);
    if (value === "" || (!isNaN(+value) && regex.test(value.toString()))) {
      setTextFieldValue(value);
    }
  };

  const handleUpdateClicked = () => {
    setDisplayUpdateButton(false);
    onChange(+textFieldValue);
  };

  if (loading) {
    return <CircularProgress />;
  } else if (isInput) {
    return (
      <Stack direction="row" gap={1}>
        <TextField
          style={{ width: 85 }}
          inputProps={{
            style: {
              padding: 10,
            },
          }}
          error={error}
          value={textFieldValue}
          data-testid="qty-textfield"
          onChange={(evt) => handleTextFieldChange(evt.target.value)}
        />
        <Button
          variant="contained"
          aria-label="update-quantity"
          data-testid="qty-update"
          onClick={handleUpdateClicked}
          disabled={textFieldValue === ""}
          style={{
            display: displayUpdateButton ? "initial" : "none",
          }}
        >
          Update
        </Button>
      </Stack>
    );
  } else {
    const menuItems = [...getSelectValues(), "Custom"].map((itemValue) => {
      return (
        <MenuItem key={`qty-${itemValue}`} value={itemValue}>
          {itemValue}
        </MenuItem>
      );
    });
    return (
      <Select
        value={value}
        renderValue={(v) => `Qty: ${v}`}
        data-testid="qty-select"
        onChange={(evt) => handleSelectChange(evt.target.value)}
        style={{ maxHeight: 40 }}
      >
        {menuItems}
      </Select>
    );
  }
};

export default QuantityInput;
