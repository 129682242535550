import { Typography } from "@mui/material";
import { useState } from "react";
import { LoadingOverlay } from "../../components";
import Logo from "../../components/logo";
import FormWrapper from "../FormWrapper";
import { StyledButton } from "../styles";

interface ISignupConfirmedProps {
  onNavigateLogin: () => void;
}

const SignupConfirmed: React.FC<ISignupConfirmedProps> = ({
  onNavigateLogin,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      onNavigateLogin();
    }, 2000);
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Logo />
      <Typography paddingY={3}>
        Your email address has been verified. You may now log in.
      </Typography>
      <StyledButton aria-label="login" variant="contained" onClick={onLogin}>
        Login
      </StyledButton>
    </>
  );
};

export default FormWrapper<ISignupConfirmedProps>(SignupConfirmed, "600px");
