import { IAddress } from "@milkmoovement/mm_shop_common_web_component";
import IShipToAddress from "../interfaces/IShipToAddress";
import IVendorDetails from "../interfaces/IVendorDetails";

const mapReducer = (acc: { [name: string]: IAddress }, val: IAddress) => {
  acc[val.label] = val;
  return acc;
};

const generateAddresses = (
  addresses: {
    [soldToName: string]: {
      id: string;
      label: string;
      address: string;
      descriptiveLabel: string;
      shipTo: {
        [shipToName: string]: IShipToAddress;
      };
    };
  },
  vendorDetails: IVendorDetails | null,
  deliveryInfo?: {
    soldToId: string;
    shipToId: string;
    shipFromId: string;
  }
) => {
  const soldTos = Object.values(addresses).reduce(
    (
      acc: IAddress[],
      val: {
        id: string;
        label: string;
        address: string;
        descriptiveLabel: string;
        shipTo: {
          [shipToName: string]: IShipToAddress;
        };
      }
    ) => {
      const { shipTo, ...soldTo } = val;
      acc.push(soldTo);
      return acc;
    },
    []
  );

  const soldToMap = soldTos.reduce(mapReducer, {});

  const soldTo =
    (deliveryInfo
      ? soldTos.find((soldTo) => soldTo.id === deliveryInfo.soldToId)
      : vendorDetails?.soldTo) ??
    soldTos?.[0] ??
    null;

  const shipTos = soldTo
    ? Object.values(addresses[soldTo.label]?.shipTo ?? {}).reduce(
        (acc: IAddress[], val: IShipToAddress) => {
          const { shipFrom, ...shipTo } = val;
          acc.push(shipTo);
          return acc;
        },
        []
      )
    : [];

  const shipToMap = shipTos.reduce(mapReducer, {});

  const shipTo =
    (deliveryInfo
      ? shipTos.find((shipTo) => shipTo.id === deliveryInfo.shipToId)
      : vendorDetails?.shipTo) ??
    shipTos?.[0] ??
    null;

  const shipFroms =
    soldTo && shipTo
      ? Object.values(
          addresses[soldTo.label]?.shipTo[shipTo.label].shipFrom ?? {}
        ).reduce((acc: any, val) => {
          acc.push(val);
          return acc;
        }, [])
      : [];

  const shipFromMap = shipFroms.reduce(mapReducer, {});

  const shipFrom =
    (deliveryInfo
      ? shipFroms.find(
          (shipFrom: any) => shipFrom.id === deliveryInfo.shipFromId
        )
      : vendorDetails?.shipFrom) ??
    shipFroms?.[0] ??
    null;

  return {
    soldTos,
    soldToMap,
    soldTo,
    shipTos,
    shipToMap,
    shipTo,
    shipFroms,
    shipFromMap,
    shipFrom,
  };
};

export default generateAddresses;
