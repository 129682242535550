import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { CommonState } from ".";
import IConnectedVendor from "../pages/shop/interfaces/IConnectedVendor";
import IVendorDetails from "../pages/shop/interfaces/IVendorDetails";
import {
  ICartItem,
  selectUser,
  cartApi,
  vendorApi,
  IProduct,
  IDeliveryInfo,
} from "@milkmoovement/mm_shop_common_web_component";
import { IAddress } from "@milkmoovement/mm_shop_common_web_component";
import { fetchProducts, selectProductsIndexed } from "./productsSlice";

export interface IVendorSelectionsSlice {
  loading?: boolean;
  loadingProducts: {
    [productId: string]: boolean;
  };
  loadingDeliveryInfo: boolean;
  error?: string | null;
  currentVendorId: string;
  // TODO Jira [MMP-236] only keep state of current vendor selected
  vendors: {
    [vendorId: string]: IVendorDetails;
  };
  // TODO Jira [MMP-229] (bert) NEED to create cart slice independent of vendor slice
  loadingCart?: boolean;
  errorCart?: string;
  productValidationErrors: {
    [productId: string]: string;
  };
  // TODO Jira [MMP-229] cart cleaning state in cart slice
  runningCartCleaning: boolean;
  isCartClean?: boolean;
  cartCleaningError?: string;
  //
  missingCartItems?: ICartItem[];
  missingCartItemsLoading?: boolean;
  //
  persistingDeliveryInfoLoading?: boolean;
}

const initialState: IVendorSelectionsSlice = {
  loading: false,
  loadingProducts: {},
  loadingDeliveryInfo: false,
  currentVendorId: "",
  vendors: {},
  productValidationErrors: {},
  runningCartCleaning: false,
};

export const setCurrentVendor = createAsyncThunk(
  "vendorSelections/setCurrentVendor",
  async (
    details: {
      vendor: IConnectedVendor | null;
      deliveryInfo?: {
        soldTo?: IAddress;
        shipTo?: IAddress;
        shipFrom?: IAddress;
      };
      cartId?: string | null;
    },
    thunkAPI
  ) => {
    const { vendor, deliveryInfo, cartId } = details;
    if (!vendor) {
      return null;
    }
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    if (cartId) {
      const cartData = await cartApi.getCartById(userId, vendor.id, cartId);

      if (!cartData.isActive) {
        throw new Error("Cart not active");
      }

      return {
        vendor,
        cartData,
        deliveryInfo,
      };
    }

    try {
      const carts = await cartApi.getCarts(userId, vendor.id);
      if (carts.length) {
        const { cartId } = carts[0];
        const cartData = await cartApi.getCartById(userId, vendor.id, cartId);

        return {
          vendor,
          cartData,
          deliveryInfo,
        };
      }
    } catch (ex) {
      const cartData = await cartApi.postCart(userId, vendor.id);
      return {
        vendor,
        cartData,
        deliveryInfo,
      };
    }
  }
);

// TODO Jira [MMP-229] (bert) should be in cart slice
export const getCurrentCart = createAsyncThunk(
  "vendorSelections/getCurrentCart",
  async (
    ids: { vendorId: string | undefined; cartId: string | undefined },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as CommonState;
    const { userId, vendorConnectedAccounts } = selectUser(state);
    const { vendorId, cartId } = ids;

    if (!vendorId || !userId || !cartId) {
      throw Error();
    }
    try {
      let vendorData;
      if (vendorId) {
        const vendor = await vendorApi.getVendorById(vendorId);
        const idx = vendorConnectedAccounts.findIndex(
          (connectedAccount: { vendorId: string; addresses: any[] }) =>
            connectedAccount.vendorId === vendorId
        );
        if (idx === -1) throw Error();

        vendorData = {
          id: vendorId,
          name: vendor.vendorName,
          addresses: vendorConnectedAccounts[idx].addresses,
          salesConfirmation: vendor.salesConfirmation,
        };
      }
      const cartData = await cartApi.getCartById(userId, vendorId, cartId);
      if (!cartData.isActive) {
        throw Error();
      }
      const { deliveryInfo } = cartData;
      const { shipTo, soldTo, shipFrom } = deliveryInfo;
      const variables = {
        shipTo: shipTo.id,
        soldTo: soldTo.id,
        shipFrom: shipFrom.id,
        shipMethod: "delivery",
      };
      thunkAPI.dispatch(fetchProducts({ userId, vendorId, variables }));
      return {
        ...(vendorData && { vendorData }),
        cartData,
      };
    } catch (ex) {
      throw Error();
    }
  }
);

export const updateDeliveryInfo = createAsyncThunk(
  "vendorSelections/updateDeliveryInfo",
  async (
    deliveryInfo: {
      shipTo?: IAddress | null | undefined;
      soldTo?: IAddress | null | undefined;
      shipFrom?: IAddress | null | undefined;
      deliveryDate?: string | undefined;
      poNumber?: string | undefined;
      note?: string | undefined;
    },
    thunkAPI
  ) => {
    if (Object.entries(deliveryInfo).length === 0) return;

    try {
      const { cart, data } = selectCurrentVendor(
        thunkAPI.getState() as CommonState
      ) as IVendorDetails;
      if (!cart) return null;
      const { userId } = selectUser(thunkAPI.getState() as CommonState);
      const vendorId = data.id;
      const cartId = cart?.cartId;
      const deliveryInfoId = cart?.deliveryInfo.deliveryInfoId;

      const { shipTo, soldTo, shipFrom, deliveryDate, poNumber, note } =
        deliveryInfo;

      await cartApi.patchCartDeliveryInfo(
        userId,
        vendorId,
        cartId,
        deliveryInfoId,
        {
          ...(shipTo && {
            shipTo: {
              id: shipTo.id,
              label: shipTo.label,
              address: shipTo.address,
              descriptiveLabel: shipTo.descriptiveLabel,
            },
          }),
          ...(soldTo && {
            soldTo: {
              id: soldTo.id,
              label: soldTo.label,
              address: soldTo.address,
              descriptiveLabel: soldTo.descriptiveLabel,
            },
          }),
          ...(shipFrom && {
            shipFrom: {
              id: shipFrom.id,
              label: shipFrom.label,
              address: shipFrom.address,
              descriptiveLabel: shipFrom.descriptiveLabel,
            },
          }),
          ...(deliveryDate && {
            desiredDeliveryDate: deliveryDate,
          }),
          poNumber,
          note,
        }
      );
    } catch {
      return null;
    }
    return deliveryInfo;
  }
);

export const checkMissingCartItems = createAsyncThunk(
  "vendorSelections/checkMissingCartItems",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as CommonState;
    const { cart } = selectCurrentVendor(state);

    if (!cart) return;
    const { items } = cart;
    const products = selectProductsIndexed(state);

    const missingCartItems = [];

    for (const item of items) {
      if (!products[item.productCode]) {
        missingCartItems.push(item);
      }
    }

    return missingCartItems;
  }
);

export const retainDeliveryInfo = createAsyncThunk(
  "vendorSelections/revertDeliveryInfo",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as CommonState;
    const { cart } = selectCurrentVendor(state);

    if (!cart) {
      throw new Error("No cart");
    }
    const { deliveryInfo } = cart;
    const { soldTo, shipTo, shipFrom } = deliveryInfo;
    return { soldTo, shipTo, shipFrom };
  }
);

export const persistDeliveryInfo = createAsyncThunk(
  "vendorSelections/persistDeliveryInfo",
  async (
    data: {
      cartItems: ICartItem[];
      deliveryInfo: {
        shipTo: IAddress;
        soldTo: IAddress;
        shipFrom: IAddress;
      };
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as CommonState;
    const { data: vendorData, cart } = selectCurrentVendor(state);
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    if (!cart) {
      throw new Error("No cart");
    }

    const { deliveryInfo } = data;

    if (
      deliveryInfo.soldTo.id === cart.deliveryInfo.soldTo?.id &&
      deliveryInfo.shipTo.id === cart.deliveryInfo.shipTo?.id &&
      deliveryInfo.shipFrom.id === cart.deliveryInfo.shipFrom?.id
    ) {
      return { deliveryInfo };
    }

    await cartApi.patchCartDeliveryInfo(
      userId,
      vendorData.id,
      cart.cartId,
      cart.deliveryInfo.deliveryInfoId,
      deliveryInfo
    );

    const missingCartItems = selectMissingCartItems(state);

    if (missingCartItems) {
      await Promise.all(
        missingCartItems.map((item) =>
          cartApi.deleteCartItem(
            userId,
            vendorData.id,
            cart.cartId,
            item.cartItemId
          )
        )
      );

      const updatedCart = await cartApi.getCartById(
        userId,
        vendorData.id,
        cart.cartId
      );

      return { deliveryInfo, updatedCart };
    }

    return { deliveryInfo };
  }
);

export const addToCart = createAsyncThunk(
  "vendorSelections/addToVendorCart",
  async (
    item: { cartItem: Omit<ICartItem, "cartItemId">; productInfo: IProduct },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as CommonState;
    const { data: vendorData, cart } = selectCurrentVendor(state);
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    if (!cart) {
      throw new Error("No cart");
    }
    const { cartItem, productInfo } = item;

    const addResponse = await cartApi.postCartItems(
      userId,
      vendorData.id,
      cart.cartId,
      [{ ...cartItem, productInfo }]
    );
    return addResponse;
  }
);

export const removeFromCart = createAsyncThunk(
  "vendorSelections/removeFromVendorCart",
  async (cartItem: ICartItem, thunkAPI) => {
    const state = thunkAPI.getState() as CommonState;
    const { data: vendorData, cart } = selectCurrentVendor(state);
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    if (!cart) {
      throw new Error("No cart");
    }
    const removeResponse = await cartApi.deleteCartItem(
      userId,
      vendorData.id,
      cart.cartId,
      cartItem.cartItemId
    );
    return { cartItemId: cartItem.cartItemId, ...removeResponse };
  }
);

export const updateItemQuantity = createAsyncThunk(
  "vendorSelections/updateItemQuantity",
  async (
    item: {
      cartItem: ICartItem;
      productInfo: IProduct;
      quantity: number;
      deliveryInstruction?: string;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as CommonState;
    const { data: vendorData, cart } = selectCurrentVendor(state);
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    if (!cart) {
      throw new Error("No cart");
    }
    const { cartItem, productInfo, quantity, deliveryInstruction } = item;
    const { cartItemId } = cartItem;

    const updateResponse = await cartApi.patchCartItem(
      userId,
      vendorData.id,
      cart.cartId,
      cartItem.cartItemId,
      quantity,
      deliveryInstruction,
      productInfo
    );
    return { ...updateResponse, cartItemId, quantity };
  }
);

export const cleanCart = createAsyncThunk(
  "vendorSelections/cleanCart",
  async (
    {
      vendorId,
      body,
    }: {
      vendorId: string;
      body: { deliveryInfo: IDeliveryInfo };
    },
    thunkAPI
  ) => {
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    return await cartApi.cleanCart(vendorId, userId, body);
  }
);

export const getCartLeadTime = createAsyncThunk(
  "vendorSelections/getCartLeadTime",
  async (vendorId: string, thunkAPI) => {
    const { userId } = selectUser(thunkAPI.getState() as CommonState);
    const { cart } = selectCurrentVendor(thunkAPI.getState() as CommonState);
    if (!cart) return;
    const { deliveryInfo, items } = cart;
    const body = {
      deliveryInfo,
      items,
    };
    return await cartApi.getCartLeadTime(vendorId, userId, body);
  }
);

const vendorSelectionsSlice = createSlice({
  name: "vendorSelections",
  initialState,
  reducers: {
    updateCurrentVendor: (
      state,
      { payload }: { payload: Omit<IVendorDetails, "data" | "cartItems"> }
    ) => {
      if (state.currentVendorId) {
        state.vendors[state.currentVendorId] = {
          ...state.vendors[state.currentVendorId],
          ...payload,
        };
      }
    },
    clearCurrentVendorOrder: (state) => {
      // TODO(ebarrett): This needs to be an async thunk
      const { vendors, currentVendorId } = state;
      const vendor = vendors[currentVendorId];
      if (vendor && vendor.cart?.items) {
        vendor.cart.items = [];
      }
      state.loadingProducts = {};
    },
    clearProductValidationErrors: (state) => {
      state.productValidationErrors = {};
      // TODO Jira [MMP-229] move to cart slice
    },
    resetCartCleaning: (state) => {
      state.runningCartCleaning = false;
      delete state.cartCleaningError;
      delete state.isCartClean;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentVendor.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        state.currentVendorId = "";
        state.vendors = {};
        return;
      }

      const { vendor, cartData, deliveryInfo } = payload;
      const { id } = vendor;
      const { deliveryInfo: cartDeliveryInfo } = cartData || {};

      const { desiredDeliveryDate, note, poNumber } = cartDeliveryInfo || {};

      state.currentVendorId = id;

      const shipFrom = deliveryInfo?.shipFrom
        ? deliveryInfo.shipFrom
        : cartDeliveryInfo?.shipFrom;

      const shipTo = deliveryInfo?.shipTo
        ? deliveryInfo.shipTo
        : cartDeliveryInfo?.shipTo;

      const soldTo = deliveryInfo?.soldTo
        ? deliveryInfo.soldTo
        : cartDeliveryInfo?.soldTo;

      state.vendors[id] = {
        data: vendor,
        cart: cartData,
        ...(desiredDeliveryDate && { deliveryDate: desiredDeliveryDate }),
        ...(note && { note }),
        ...(poNumber && { poNumber }),
        ...(shipFrom && { shipFrom }),
        ...(shipTo && { shipTo }),
        ...(soldTo && { soldTo }),
      };

      delete state.errorCart;
    });

    builder.addCase(setCurrentVendor.rejected, (state, action) => {
      state.errorCart = "Not found";
    });

    // may be able to combine builder for setCurrentVendor and getCurrentCart?
    builder.addCase(getCurrentCart.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { vendorData, cartData } = action.payload;
      const { deliveryInfo } = cartData;
      const { desiredDeliveryDate, note, poNumber, shipFrom, shipTo, soldTo } =
        deliveryInfo;

      if (vendorData) {
        state.vendors[vendorData.id] = {
          data: vendorData,
        };
        state.currentVendorId = vendorData.id;
      }

      const vendorId = vendorData ? vendorData.id : state.currentVendorId;

      // TODO Jira [MMP-229] (bert) NEED to create cart slice independent of vendor slice
      state.vendors[vendorId].cart = cartData;
      // TODO Jira [MMP-229] (bert) these fields should be stored on cart.deliveryInfo
      state.vendors[vendorId].deliveryDate = desiredDeliveryDate;
      state.vendors[vendorId].note = note;
      state.vendors[vendorId].poNumber = poNumber;
      state.vendors[vendorId].shipFrom = shipFrom;
      state.vendors[vendorId].shipTo = shipTo;
      state.vendors[vendorId].soldTo = soldTo;

      state.loadingCart = false;
      delete state.errorCart;
      state.productValidationErrors = {};
    });

    builder.addCase(getCurrentCart.pending, (state, action) => {
      state.loadingCart = true;
      delete state.errorCart;
    });

    builder.addCase(getCurrentCart.rejected, (state, action) => {
      state.loadingCart = false;
      state.errorCart = action.error.message ?? "Request failed";
    });

    builder.addCase(addToCart.fulfilled, (state, action) => {
      const { addedItems } = action.payload;
      const { cart } = state.vendors[state.currentVendorId];
      if (!cart) return;
      cart.items = [...cart.items, ...addedItems];
    });

    builder.addCase(removeFromCart.fulfilled, (state, action) => {
      const { cartItemId } = action.payload;
      const { cart } = state.vendors[state.currentVendorId];
      if (!cart) return;
      cart.items = cart.items.filter((item) => item.cartItemId !== cartItemId);
    });

    builder.addCase(updateItemQuantity.fulfilled, (state, action) => {
      const { cartItemId, quantity } = action.payload;
      const { cart } = state.vendors[state.currentVendorId];
      if (!cart) return;
      const item = cart.items.find((item) => item.cartItemId === cartItemId);
      if (!item) return;
      item.quantity = quantity;
    });

    builder.addCase(updateDeliveryInfo.pending, (state, action) => {
      state.loadingDeliveryInfo = true;
    });

    builder.addCase(updateDeliveryInfo.fulfilled, (state, action) => {
      state.loadingDeliveryInfo = false;
      const { payload } = action;
      if (!payload) return;
      vendorSelectionsSlice.caseReducers.updateCurrentVendor(state, {
        payload,
      });
    });

    builder.addCase(updateDeliveryInfo.rejected, (state, action) => {
      state.loadingDeliveryInfo = false;
    });

    builder.addCase(cleanCart.pending, (state) => {
      state.runningCartCleaning = true;
      state.isCartClean = false;
      delete state.cartCleaningError;
    });
    builder.addCase(cleanCart.rejected, (state) => {
      state.runningCartCleaning = false;
      state.isCartClean = false;
      state.cartCleaningError =
        "Error connecting to the vendor.  Please try again at a later time.";
    });
    builder.addCase(cleanCart.fulfilled, (state, action) => {
      state.runningCartCleaning = false;
      delete state.cartCleaningError;

      if (
        action.payload.lastStep?.functionName ===
          vendorApi.VENDOR_PROCESS_FUNCTIONS.GET_CART &&
        action.payload.lastStep?.error ===
          vendorApi.VENDOR_PROCESS_ERRORS.MISSING_CART_ID
      ) {
        state.isCartClean = true;
        return;
      }
      state.cartCleaningError =
        "Error connecting to the vendor.  Please try again at a later time.";
    });

    builder.addCase(getCartLeadTime.fulfilled, (state, action) => {
      const { cart } = state.vendors[state.currentVendorId];
      if (!cart) return;
      cart.leadTime = action.payload;
      cart.leadTimeLoading = false;
      delete cart.leadTimeError;
    });

    builder.addCase(getCartLeadTime.pending, (state) => {
      const { cart } = state.vendors[state.currentVendorId];
      if (!cart) return;
      cart.leadTimeLoading = true;
      delete cart.leadTimeError;
    });

    builder.addCase(getCartLeadTime.rejected, (state) => {
      const { cart } = state.vendors[state.currentVendorId];
      if (!cart) return;
      cart.leadTimeLoading = false;
      cart.leadTimeError = true;
    });

    // TODO improve?
    builder.addCase(checkMissingCartItems.fulfilled, (state, action) => {
      state.missingCartItems = action.payload;
      state.missingCartItemsLoading = false;
    });

    builder.addCase(checkMissingCartItems.pending, (state, action) => {
      state.missingCartItemsLoading = true;
    });

    builder.addCase(checkMissingCartItems.rejected, (state, action) => {
      delete state.missingCartItems;
      state.missingCartItemsLoading = false;
    });

    builder.addCase(retainDeliveryInfo.fulfilled, (state, action) => {
      const { payload } = action;
      vendorSelectionsSlice.caseReducers.updateCurrentVendor(state, {
        payload,
      });
    });

    builder.addCase(persistDeliveryInfo.fulfilled, (state, action) => {
      delete state.persistingDeliveryInfoLoading;
      const { payload } = action;
      const { deliveryInfo, updatedCart } = payload;
      const vendor = state.vendors[state.currentVendorId];
      if (!vendor) return;
      state.vendors[state.currentVendorId].shipFrom = deliveryInfo.shipFrom;
      state.vendors[state.currentVendorId].shipTo = deliveryInfo.shipTo;
      state.vendors[state.currentVendorId].soldTo = deliveryInfo.soldTo;
      const { cart } = vendor;
      if (!cart || !updatedCart) return;
      state.vendors[state.currentVendorId].cart = updatedCart;
    });

    builder.addCase(persistDeliveryInfo.pending, (state, action) => {
      state.persistingDeliveryInfoLoading = true;
    });

    builder.addCase(persistDeliveryInfo.rejected, (state, action) => {
      delete state.persistingDeliveryInfoLoading;
    });

    builder.addMatcher(
      isAnyOf(retainDeliveryInfo.fulfilled, persistDeliveryInfo.fulfilled),
      (state, action) => {
        delete state.missingCartItems;
      }
    );

    builder.addMatcher(isAnyOf(removeFromCart.pending), (state, action) => {
      const cartItem: Omit<ICartItem, "cartItemId"> = action.meta.arg;
      state.loadingProducts[cartItem.productCode] = true;
    });

    builder.addMatcher(
      isAnyOf(addToCart.pending, updateItemQuantity.pending),
      (state, action) => {
        const item = action.meta.arg;
        const cartItem: Omit<ICartItem, "cartItemId"> = item.cartItem;
        state.loadingProducts[cartItem.productCode] = true;
      }
    );

    builder.addMatcher(
      isAnyOf(addToCart.fulfilled, updateItemQuantity.fulfilled),
      (state, action) => {
        const item = action.meta.arg;
        const cartItem: Omit<ICartItem, "cartItemId"> = item.cartItem;
        delete state.loadingProducts[cartItem?.productCode];
        delete state.productValidationErrors[cartItem?.productCode];
      }
    );

    builder.addMatcher(
      isAnyOf(addToCart.rejected, updateItemQuantity.rejected),
      (state, action) => {
        const item = action.meta.arg;
        const cartItem: Omit<ICartItem, "cartItemId"> = item.cartItem;
        delete state.loadingProducts[cartItem?.productCode];

        state.productValidationErrors[cartItem.productCode] =
          action.error.message || "Verification Error";
      }
    );

    builder.addMatcher(
      isAnyOf(removeFromCart.fulfilled, removeFromCart.rejected),
      (state, action) => {
        const cartItem: Omit<ICartItem, "cartItemId"> = action.meta.arg;
        delete state.loadingProducts[cartItem?.productCode];
        delete state.productValidationErrors[cartItem?.productCode];
      }
    );

    builder.addMatcher(isPending, (state, action) => {
      if (!action.type.startsWith("vendorSelections/")) return;
      state.loading = true;
      state.error = null;
    });

    builder.addMatcher(isRejected, (state, action) => {
      if (!action.type.startsWith("vendorSelections/")) return;
      state.loading = false;
      state.error = action.error.message ?? "Request failed";
    });

    builder.addMatcher(isFulfilled, (state, action) => {
      if (!action.type.startsWith("vendorSelections/")) return;
      state.loading = false;
      state.error = null;
    });
  },
});

export const {
  updateCurrentVendor,
  clearCurrentVendorOrder,
  clearProductValidationErrors,
  resetCartCleaning,
} = vendorSelectionsSlice.actions;
export const selectVendorMap = ({ vendorSelections }: CommonState) =>
  vendorSelections.vendors;
export const selectCurrentVendor = ({ vendorSelections }: CommonState) =>
  vendorSelections.vendors[vendorSelections.currentVendorId] || null;
export const selectVendorSelectionsLoading = ({
  vendorSelections,
}: CommonState) => !!vendorSelections.loading;
export const selectVendorSelectionsLoadingCart = ({
  vendorSelections,
}: CommonState) => !!vendorSelections.loadingCart;
export const selectVendorSelectionsErrorCart = ({
  vendorSelections,
}: CommonState) => vendorSelections.errorCart;
export const selectCartId = ({ vendorSelections }: CommonState) =>
  vendorSelections.vendors[vendorSelections.currentVendorId]?.cart?.cartId ??
  null;
export const selectLoadingProducts = ({ vendorSelections }: CommonState) => {
  return vendorSelections.loadingProducts;
};
export const selectLoadingDeliveryInfo = ({ vendorSelections }: CommonState) =>
  vendorSelections.loadingDeliveryInfo;
export const selectProductValidationErrors = ({
  vendorSelections,
}: CommonState) => vendorSelections.productValidationErrors;

export const selectCartCleaning = ({ vendorSelections }: CommonState) => ({
  cartCleaningError: vendorSelections.cartCleaningError,
  isCartClean: vendorSelections.isCartClean,
  runningCartCleaning: vendorSelections.runningCartCleaning,
});

export const selectCartLeadTime = ({ vendorSelections }: CommonState) => {
  const cart = vendorSelections.vendors[vendorSelections.currentVendorId]?.cart;
  return {
    leadTime: cart?.leadTime,
    leadTimeLoading: cart?.leadTimeLoading,
    leadTimeError: cart?.leadTimeError,
  };
};

export const selectMissingCartItems = ({ vendorSelections }: CommonState) =>
  vendorSelections.missingCartItems;

export const selectMissingCartItemsLoading = ({
  vendorSelections,
}: CommonState) => vendorSelections.missingCartItemsLoading;

export const selectPersistingDeliveryInfoLoading = ({
  vendorSelections,
}: CommonState) => vendorSelections.persistingDeliveryInfoLoading;

export default vendorSelectionsSlice.reducer;
