import getCartById from "./getCartById";
import getCarts from "./getCarts";
import getCartLeadTime from "./getCartLeadTime";
import patchCartItem from "./patchCartItem";
import postCart from "./postCart";
import postCartItems from "./postCartItems";
import patchCartDeliveryInfo from "./patchCartDeliveryInfo";
import deleteCartItem from "./deleteCartItem";
import cleanCart from "./cleanCart";

const cartApi = {
  getCarts,
  getCartById,
  getCartLeadTime,
  postCart,
  postCartItems,
  patchCartItem,
  patchCartDeliveryInfo,
  deleteCartItem,
  cleanCart,
};

export default cartApi;
