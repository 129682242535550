import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchSelect from "../../common/components/SearchSelect";
import ConnectedVendorGrid from "./ConnectedVendorGrid";
import IConnectedVendor from "../../../interfaces/IConnectedVendor";
import IProcurementMethod from "../../../interfaces/IProcurementMethod";
import { IAddress } from "@milkmoovement/mm_shop_common_web_component";

const Root = styled(Paper)(() => ({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "5px",
  gap: "35px",
  justifyContent: "space-between",
  minWidth: "min(100%, 600px)",
}));

const StyledConnectVendorsStack = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: "30px",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const StyledButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
  },
}));

const StyledConnectVendorGridContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginRight: "120px",
  },
}));

const StyledRequired = styled("span")(() => ({
  color: "red",
}));

interface IProps {
  connectedVendors: IConnectedVendor[] | null;
  selectedVendor: IConnectedVendor | null;
  soldToAddress: IAddress | null;
  shipToAddress: IAddress | null;
  shipFromAddress: IAddress | null;
  soldToAddresses: IAddress[];
  shipToAddresses: IAddress[];
  shipFromAddresses: IAddress[];
  procurementMethod: IProcurementMethod;
  selectedVendorLoading: boolean;
  onNavigateToVendorConnections: () => void;
  onSelectVendor: (vendor: IConnectedVendor) => void;
  onSoldToAddressChange: (value: string | null) => void;
  onShipToAddressChange: (value: string | null) => void;
  onShipFromAddressChange: (value: string | null) => void;
  onProcurementMethodChange: (event: { target: any }) => void;
  onToggleVendorSelect: () => void;
}

const ConnectedVendorSelectForm: React.FC<IProps> = ({
  connectedVendors,
  selectedVendor,
  soldToAddress,
  shipToAddress,
  shipFromAddress,
  soldToAddresses,
  shipToAddresses,
  shipFromAddresses,
  procurementMethod,
  selectedVendorLoading,
  onNavigateToVendorConnections,
  onSelectVendor,
  onSoldToAddressChange,
  onShipToAddressChange,
  onShipFromAddressChange,
  onProcurementMethodChange,
  onToggleVendorSelect,
}) => (
  <Root>
    <StyledConnectVendorsStack>
      <Stack gap={1}>
        <Typography variant="h6">Connected Vendors</Typography>
        <Typography>
          {connectedVendors && connectedVendors.length > 0
            ? "Select one of the following vendors"
            : "Click the button to connect vendors"}
        </Typography>
      </Stack>
      <StyledButtonContainer>
        <Button
          aria-label="navigate-to-vendor-connections"
          variant="contained"
          onClick={onNavigateToVendorConnections}
        >
          Manage Connections
        </Button>
      </StyledButtonContainer>
    </StyledConnectVendorsStack>

    {connectedVendors && (
      <StyledConnectVendorGridContainer>
        <ConnectedVendorGrid
          connectedVendors={connectedVendors}
          selectedVendor={selectedVendor}
          onSelectVendor={onSelectVendor}
        />
      </StyledConnectVendorGridContainer>
    )}
    {selectedVendorLoading && (
      <>
        <Divider />
        <Stack direction="row" justifyContent="space-around">
          <CircularProgress />
        </Stack>
      </>
    )}
    {selectedVendor && !selectedVendorLoading && (
      <>
        <Divider />

        <Stack alignItems="center">
          <Stack direction="column" gap={2} width="min(100%, 400px)">
            <Typography fontWeight={700}>
              Sold To <StyledRequired>*</StyledRequired>
            </Typography>
            <SearchSelect
              options={soldToAddresses.map((address) => address.label)}
              value={soldToAddress?.label || null}
              onValueChange={onSoldToAddressChange}
            />

            <Typography fontWeight={700}>
              Ship To <StyledRequired>*</StyledRequired>
            </Typography>
            <SearchSelect
              disabled={shipToAddresses.length === 0}
              options={shipToAddresses.map((address) => address.label)}
              value={shipToAddress?.label || null}
              onValueChange={onShipToAddressChange}
            />

            <Typography fontWeight={700}>
              Ship From <StyledRequired>*</StyledRequired>
            </Typography>
            <SearchSelect
              disabled={shipFromAddresses.length === 0}
              options={shipFromAddresses.map((address) => address.label)}
              value={shipFromAddress?.label || null}
              onValueChange={onShipFromAddressChange}
            />
          </Stack>
        </Stack>

        <FormControl>
          <Stack alignItems="center">
            <StyledRadioGroup
              row
              value={procurementMethod}
              onChange={onProcurementMethodChange}
            >
              <FormControlLabel
                aria-label="delivery"
                value="delivery"
                control={<Radio />}
                label="Delivery"
              />
              {/* Currently hide pickup option */}
              {/* <FormControlLabel
                aria-label="pickup"
                value="pickup"
                control={<Radio />}
                label="Pickup"
              /> */}
            </StyledRadioGroup>
          </Stack>
        </FormControl>
        <Stack direction="row" justifyContent="center">
          <Button
            aria-label="vendor-select"
            onClick={onToggleVendorSelect}
            variant="contained"
            disabled={!soldToAddress || !shipToAddress || !shipFromAddress}
          >
            Show Products
          </Button>
        </Stack>
      </>
    )}
  </Root>
);
export default ConnectedVendorSelectForm;
