import { useState } from "react";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Box from "@mui/material/Box";
import FormWrapper from "../FormWrapper";
import Logo from "../../components/logo";
import { resetPassword } from "../../cognito";
import {
  StyledAlert,
  StyledButton,
  StyledFormControl,
  StyledHeader,
  StyledInputLabel,
  StyledOutlinedInput,
} from "../styles";

type IFormInputs = {
  password: string;
  confirmPassword: string;
};

const formSchema = Joi.object({
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.ref("password"),
});

interface IResetPasswordProps {
  onNavigateBack: () => void;
  isUrlHash?: boolean;
}

const ResetPassword: React.FC<IResetPasswordProps> = ({
  onNavigateBack,
  isUrlHash,
}) => {
  const getQuery = () => {
    if (!isUrlHash) return window.location.search;
    const { hash } = window.location;
    const index = hash.indexOf("?");
    if (index === -1) return "";
    return hash.substring(index);
  };
  const query = getQuery();

  const urlParameters = new URLSearchParams(query);

  const username = urlParameters.get("user_name");
  const confirmationCode = urlParameters.get("confirmation_code");

  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState(false);

  const { register, handleSubmit, formState } = useForm<IFormInputs>({
    resolver: joiResolver(formSchema),
    mode: "onChange",
  });

  const { errors, isValid } = formState;

  const onSubmit = async ({ password }: IFormInputs) => {
    try {
      if (username !== null && confirmationCode !== null) {
        await resetPassword(username, password, confirmationCode);
        setAlert("");
        setSuccess(true);
      }
    } catch (err: any) {
      if (err.code === "ExpiredCodeException")
        setAlert(
          "This password reset link has expired, please reset your password again to receive a new link."
        );
      else setAlert(err.message);
    }
  };

  return (
    <>
      <Logo />
      <StyledHeader>Reset password</StyledHeader>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        component="form"
        width={400}
        onSubmit={handleSubmit(onSubmit)}
      >
        {alert && <StyledAlert severity="error">{alert}</StyledAlert>}
        {!success ? (
          <>
            <StyledFormControl>
              <StyledInputLabel error={!!errors.password}>
                Password *
              </StyledInputLabel>
              <StyledOutlinedInput
                type="password"
                {...register("password")}
                label="Password *"
                error={!!errors.password}
              />
            </StyledFormControl>
            <StyledFormControl>
              <StyledInputLabel error={!!errors.confirmPassword}>
                Repeat Password *
              </StyledInputLabel>
              <StyledOutlinedInput
                type="password"
                {...register("confirmPassword")}
                label="Repeat Password *"
                error={!!errors.confirmPassword}
              />
            </StyledFormControl>
            <Box display="flex" gap={2} justifyContent="center">
              <StyledButton
                variant="outlined"
                onClick={() => {
                  onNavigateBack();
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                Reset Password
              </StyledButton>
            </Box>
          </>
        ) : (
          <>
            <StyledAlert severity="success">
              Your password has been successfully changed.
            </StyledAlert>
            <Box display="flex" gap={2} justifyContent="center">
              <StyledButton
                variant="contained"
                onClick={() => {
                  onNavigateBack();
                }}
              >
                Login
              </StyledButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default FormWrapper<IResetPasswordProps>(ResetPassword, "600px");
