import {
  State,
  CommonState as ImportedState,
  renderWithProviders as renderWithCommonProviders,
  commonReducers,
  PreloadedCombinedState,
} from "@milkmoovement/mm_shop_common_web_component";
import vendorSelections from "./vendorSelectionsSlice";
import vendorFaq from "./vendorFaqSlice";
import products from "./productsSlice";
import {
  Action,
  AnyAction,
  PreloadedState,
  ReducersMapObject,
} from "@reduxjs/toolkit";
import { RenderOptions } from "@testing-library/react";

export const reducers = {
  products,
  vendorSelections,
  vendorFaq,
};

interface ExtendedRenderOptions<T = any, A extends Action = AnyAction>
  extends Omit<RenderOptions, "queries"> {
  extraReducers?: ReducersMapObject<T, A>;
  preloadedState?: PreloadedCombinedState<typeof commonReducers> &
    PreloadedCombinedState<typeof reducers> &
    PreloadedState<State<ReducersMapObject<T>>>;
}

export function renderWithProviders<T>(
  ui: React.ReactElement,
  { preloadedState, ...renderOptions }: ExtendedRenderOptions<T> = {}
) {
  return renderWithCommonProviders(ui, {
    preloadedState,
    reducers,
    ...renderOptions,
  });
}

export type CommonState = State<typeof reducers> & ImportedState;
