import Box from "@mui/material/Box";
import DarkLogo from "../../assets/logos/DarkLogo";

const Logo: React.FC = () => (
  <Box component="div" sx={{ width: "250px" }}>
    <DarkLogo />
  </Box>
);

export default Logo;
