import axios from "../../network/axios";

const deleteCartItem = async (
  userId: string,
  vendorId: string,
  cartId: string,
  cartItemId: string
) => {
  try {
    const url = `${process.env.REACT_APP_CART_API_URL}/carts/${cartId}/items/${cartItemId}?userId=${userId}&vendorId=${vendorId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (e) {
    throw new Error("Could not delete cart item");
  }
};

export default deleteCartItem;
