import {
  AuthenticationDetails,
  ClientMetadata,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from "amazon-cognito-identity-js";
import { TypedEmitter } from "tiny-typed-emitter";

let userPool: CognitoUserPool | undefined;

interface CognitoClassEvents {
  signIn:
    | ((session: CognitoUserSession) => void)
    | ((session: CognitoUserSession) => Promise<void>);
  signOut: (() => void) | (() => Promise<void>);
}

class CognitoEvents extends TypedEmitter<CognitoClassEvents> {}

export const cognitoEvents = new CognitoEvents();

export const configureUserPool = (): CognitoUserPool => {
  if (userPool !== undefined) return userPool;

  if (
    !process.env.REACT_APP_COGNITO_CLIENT_ID ||
    !process.env.REACT_APP_COGNITO_USER_POOL_ID
  ) {
    throw new Error("User pool configuration not found");
  }

  userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId:
      localStorage.getItem("mm.login.appClientId") ||
      process.env.REACT_APP_COGNITO_CLIENT_ID,
  });

  return userPool;
};

export const clearUserPool = () => {
  userPool = undefined;
  localStorage.removeItem("mm.login.appClientId");
  localStorage.removeItem("mm.login.internalLogin");
};

export const createUser = async (
  username: string,
  password: string,
  attributes: { [key: string]: string | undefined }
) => {
  const userAttributes: CognitoUserAttribute[] = Object.entries(attributes)
    .filter((item): item is [string, string] => item[1] !== undefined)
    .map(
      ([key, value]) =>
        new CognitoUserAttribute({
          Name: key,
          Value: value,
        })
    );

  const userPool = configureUserPool();

  return new Promise<ISignUpResult | undefined>((resolve, reject) => {
    userPool.signUp(username, password, userAttributes, [], (err, result) =>
      err ? reject(err) : resolve(result)
    );
  });
};

export const authenticateUser = async (
  username: string,
  password: string,
  metadata?: ClientMetadata,
  existingUser?: CognitoUser
): Promise<{ user: CognitoUser; MFARequired: boolean }> => {
  const userPool = configureUserPool();
  return new Promise((resolve, reject) => {
    if (!existingUser) {
      existingUser = returnUser(username, userPool);
    }
    const user = existingUser;
    if (!user) {
      reject(new Error("User not found"));
      return;
    }
    user.authenticateUser(
      new AuthenticationDetails({
        Username: username,
        Password: password,
        ClientMetadata: metadata,
      }),
      {
        onSuccess: async (session) => {
          for (const listner of cognitoEvents.listeners("signIn")) {
            await listner(session);
          }
          localStorage.setItem("mm.login.currentUsername", username);
          resolve({ user: user, MFARequired: false });
        },
        onFailure: reject,
        totpRequired: async function () {
          resolve({ user: user, MFARequired: true });
        },
      }
    );
  });
};

export const sendMFACode = async (
  user: CognitoUser,
  username: string,
  mfaCode: string
): Promise<{ user: CognitoUser; MFARequired: boolean }> => {
  return new Promise((resolve, reject) => {
    user.sendMFACode(
      mfaCode,
      {
        onSuccess: async (session) => {
          for (const listner of cognitoEvents.listeners("signIn")) {
            await listner(session);
          }
          localStorage.setItem("mm.login.currentUsername", username);
          resolve({ user: user, MFARequired: false });
        },
        onFailure: reject,
      },
      "SOFTWARE_TOKEN_MFA"
    );
  });
};

const returnUser = (username: string, userPool: CognitoUserPool) => {
  const new_user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  return new_user;
};

export const signOut = async (clearUsername = true) => {
  const userPool = configureUserPool();
  const user = userPool.getCurrentUser();

  for (const listner of cognitoEvents.listeners("signOut")) await listner();

  if (clearUsername) {
    localStorage.removeItem("mm.login.currentUsername");
    localStorage.removeItem("mm.login.appClientId"); // Remove prior SSO client ID so standard login flow can be used without hard refresh
  }

  await new Promise<void>((resolve) => {
    const onSignOut = () => {
      resolve();
    };
    if (!user) {
      onSignOut();
    }
    user?.signOut(() => {
      onSignOut();
    });
  });
};

export const getCurrentSession = async (): Promise<CognitoUserSession> => {
  const userPool = configureUserPool();
  const user = userPool.getCurrentUser();

  if (user === null) {
    throw new Error("No current user");
  }
  return new Promise((resolve, reject) =>
    user.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        reject(err);
      } else if (!session.isValid()) {
        reject(new Error("Invalid session; user must log in again"));
      } else resolve(session);
    })
  );
};

export const requestPasswordReset = async (
  username: string,
  metadata?: ClientMetadata
) => {
  const userPool = configureUserPool();
  return new Promise((resolve, reject) =>
    new CognitoUser({ Username: username, Pool: userPool }).forgotPassword(
      {
        onSuccess: resolve,
        onFailure: reject,
      },
      metadata
    )
  );
};

export const resetPassword = async (
  username: string,
  password: string,
  confirmationCode: string
) => {
  const userPool = configureUserPool();
  return new Promise((resolve, reject) =>
    new CognitoUser({
      Username: username,
      Pool: userPool,
    }).confirmPassword(confirmationCode, password, {
      onSuccess: () => resolve(void 0),
      onFailure: reject,
    })
  );
};
export const clearCognitoCache = () => {
  for (const key of Object.keys(localStorage))
    if (key.toLowerCase().includes("cognito")) localStorage.removeItem(key);
};
