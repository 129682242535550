import { styled, Theme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
// import Avatar from "@mui/material/Avatar";
import {
  navbarToggle,
  navbarToggleMobile,
} from "../../store/reducers/ui/navbarSlice";
import { useCommonDispatch } from "../../hooks";
import useThemeMediaQuery from "../../hooks/useThemeMediaQuery";
import { ISettings } from "../../settings";

interface IStyledToolbarProps {
  settings: ISettings;
}

const StyledToolbar = styled("div", {
  shouldForwardProp: (prop) => prop !== "settings",
})<IStyledToolbarProps>(({ theme, settings }) => ({
  position: "fixed",
  top: 0,
  height: settings.toolbarHeight,
  padding: "0 8px",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  borderBottom: "1px solid lightgrey",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 100,
}));

interface IProps {
  settings: ISettings;
}

const Toolbar: React.FC<IProps> = ({ settings }: IProps) => {
  const dispatch = useCommonDispatch();
  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const toggle = isMobile ? navbarToggleMobile : navbarToggle;

  return (
    <StyledToolbar settings={settings}>
      <div>
        <IconButton onClick={() => dispatch(toggle())}>
          <Icon>menu</Icon>
        </IconButton>
      </div>
      {/* <div>
        <Avatar>{""}</Avatar>
      </div> */}
    </StyledToolbar>
  );
};

export default Toolbar;
