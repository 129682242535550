import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export const datadogInit = () => {
  const {
    REACT_APP_DEV_MODE = "false",
    REACT_APP_DATADOG_APPLICATION_ID = "",
    REACT_APP_DATADOG_CLIENT_TOKEN = "",
    REACT_APP_DATADOG_SITE = "datadoghq.com",
    REACT_APP_DATADOG_SERVICE = "shop",
    REACT_APP_DATADOG_VERSION = "0.1.0",
    REACT_APP_DATADOG_ENV = "production",
  } = process.env;

  const devMode = REACT_APP_DEV_MODE === "true";
  const beforeSend = () => !devMode;

  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: REACT_APP_DATADOG_SITE,
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV,
    version: REACT_APP_DATADOG_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend,
  });

  datadogLogs.init({
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: REACT_APP_DATADOG_SITE,
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV,
    version: REACT_APP_DATADOG_VERSION,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    forwardReports: "all",
    sampleRate: 100,
    beforeSend,
  });

  datadogRum.startSessionReplayRecording();
};
