import createTheme from "@mui/material/styles/createTheme";

const theme = createTheme({
  palette: {
    primary: {
      light: "#67d3ff",
      main: "#01a2f5",
      dark: "#0074c2",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#676d7e",
      main: "#3c4252",
      dark: "#151b29",
      contrastText: "#FFFFFF",
    },
    background: {
      paper: "#FFFFFF",
      default: "#f6f7f9",
    },
    warning: {
      main: "#ffa351",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    subtitle2: {
      fontSize: 11,
      fontWeight: 300,
    },
  },
});

export default theme;
