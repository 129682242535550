import { IProduct } from "../../interfaces";
import axios from "../../network/axios";

const patchCartItem = async (
  userId: string,
  vendorId: string,
  cartId: string,
  cartItemId: string,
  quantity: number,
  deliveryInstruction?: string,
  productInfo?: IProduct
) => {
  try {
    const url = `${process.env.REACT_APP_CART_API_URL}/carts/${cartId}/items/${cartItemId}?userId=${userId}&vendorId=${vendorId}`;
    const { data } = await axios.patch(url, {
      quantity,
      deliveryInstruction,
      ...(productInfo && { productInfo }),
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (
      e?.response?.status === 400 &&
      e?.response?.data?.isValid === false &&
      e?.response?.data?.errorMessage
    ) {
      throw new Error(e.response.data.errorMessage);
    }
    throw new Error("Could not update cart item quantity");
  }
};

export default patchCartItem;
