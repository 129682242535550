import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

const StyledPage = styled("div")(() => ({
  display: "flex",
  // backgroundColor: theme.palette.secondary.dark,
  backgroundColor: "#151B29",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "100%",
  minHeight: "100vh",
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})<BoxProps & { maxWidth: string | undefined }>(({ theme, maxWidth }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "15px !important",
  padding: "30px",
  backgroundColor: theme.palette.background.default,
  borderRadius: "10px",
  gap: "20px",
  ...(!!maxWidth ? { width: "100%", maxWidth } : { maxWidth: "100vw" }),
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FormWrapper<T = any>(Component: React.ElementType, maxWidth?: string) {
  return function (props: T) {
    return (
      <StyledPage>
        <StyledBox maxWidth={maxWidth}>
          <Component {...props} />
        </StyledBox>
      </StyledPage>
    );
  };
}

export default FormWrapper;
