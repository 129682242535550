import { IAddress } from "@milkmoovement/mm_shop_common_web_component";
import IShipToAddress from "../interfaces/IShipToAddress";

const shipToReducer = (
  acc: {
    [shipToLabel: string]: IShipToAddress;
  },
  val: IShipToAddress
) => {
  acc[val.label] = {
    id: val.id,
    label: val.label,
    address: val.address,
    descriptiveLabel: val.descriptiveLabel,
    shipFrom: val.shipFrom,
  };
  return acc;
};

const soldToReducer = (
  acc: {
    [soldToLabel: string]: {
      id: string;
      label: string;
      address: string;
      descriptiveLabel: string;
      shipTo: { [soldToLabel: string]: IShipToAddress };
    };
  },
  val: {
    soldTo: IAddress;
    shipTo: IShipToAddress[];
  }
) => {
  acc[val.soldTo.label] = {
    id: val.soldTo.id,
    label: val.soldTo.label,
    address: val.soldTo.address ?? "",
    descriptiveLabel: val.soldTo.descriptiveLabel ?? "",
    shipTo: val.shipTo.reduce(shipToReducer, {}),
  };
  return acc;
};
const addressMapReducer = (
  acc: {
    [vendorName: string]: {
      [soldToLabel: string]: {
        id: string;
        label: string;
        address: string;
        descriptiveLabel: string;
        shipTo: { [soldToLabel: string]: IShipToAddress };
      };
    };
  },
  val: {
    id: string;
    name: string;
    addresses: {
      soldTo: IAddress;
      shipTo: any[];
    }[];
  }
) => {
  acc[val.name] = val.addresses.reduce(soldToReducer, {});

  return acc;
};

export default addressMapReducer;
