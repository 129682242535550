import NavGroup from "./NavGroup";
import NavHeading from "./NavHeading";
import NavItem from "./NavItem";
import { INavigationElement } from "../../../../interfaces/INavigation";

const components = {
  item: NavItem,
  heading: NavHeading,
  group: NavGroup,
};

interface IProps {
  item: INavigationElement;
  nested?: boolean;
  action?: () => void;
}

const Nav: React.FC<IProps> = ({ item, nested, action }) => {
  const C = components[item.type];
  const props = item.type === "item" ? { item, nested, action } : { item };
  return <C {...props} />;
};

export default Nav;
