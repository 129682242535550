import { createSlice } from "@reduxjs/toolkit";
import { CommonState } from "../../store";

export interface INavbarState {
  open: boolean;
  mobileOpen: boolean;
}

const initialState: INavbarState = {
  open: true,
  mobileOpen: false,
};

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    navbarToggleMobile: (state) => {
      state.mobileOpen = !state.mobileOpen;
    },
    navbarOpenMobile: (state) => {
      state.mobileOpen = true;
    },
    navbarCloseMobile: (state) => {
      state.mobileOpen = false;
    },
    navbarClose: (state) => {
      state.open = false;
    },
    navbarOpen: (state) => {
      state.open = true;
    },
    navbarToggle: (state) => {
      state.open = !state.open;
    },
  },
});

export const {
  navbarOpen,
  navbarClose,
  navbarToggle,
  navbarOpenMobile,
  navbarCloseMobile,
  navbarToggleMobile,
} = navbarSlice.actions;

export const selectNavbar = ({ ui }: CommonState) => ui.navbar;

export default navbarSlice.reducer;
