import {
  IAddress,
  selectUser,
  useCommonDispatch,
  vendorApi,
} from "@milkmoovement/mm_shop_common_web_component";
import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectCartId,
  selectCurrentVendor,
  selectVendorSelectionsLoading,
  setCurrentVendor,
  updateCurrentVendor,
  updateDeliveryInfo,
} from "../../../../store/vendorSelectionsSlice";
import IConnectedVendor from "../../interfaces/IConnectedVendor";
import IShipToAddress from "../../interfaces/IShipToAddress";
import ConnectedVendorSelectScreen from "../../screens/connectedVendorSelect";
import addressMapReducer from "../../utils/addressMapReducer";
import generateAddresses from "../../utils/generateAddresses";

const ConnectedVendorSelect: React.FC = () => {
  const dispatch = useCommonDispatch();
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const [vendors, setVendors] = useState<any[] | null>(null); // TODO get from store
  const [connectedVendors, setConnectedVendors] = useState<
    IConnectedVendor[] | null
  >(null);

  const [shipToAddresses, setShipToAddresses] = useState<IAddress[]>([]);
  const [soldToAddresses, setSoldToAddresses] = useState<IAddress[]>([]);
  const [shipFromAddresses, setShipFromAddresses] = useState<IAddress[]>([]);

  const [requiresAddressUpdate, setRequiresAddressUpdate] =
    useState<boolean>(false);
  const [addressMap, setAddressMap] = useState<{
    [vendorName: string]: {
      [soldToName: string]: {
        id: string;
        label: string;
        address: string;
        descriptiveLabel: string;
        shipTo: {
          [shipToName: string]: IShipToAddress;
        };
      };
    };
  }>({});

  const [shipToAddressMap, setShipToAddressMap] = useState<{
    [label: string]: IAddress;
  }>({});
  const [soldToAddressMap, setSoldToAddressMap] = useState<{
    [label: string]: IAddress;
  }>({});
  const [shipFromAddressMap, setShipFromAddressMap] = useState<{
    [label: string]: IAddress;
  }>({});

  const [initialAddresses, setInitialAddresses] = useState<{
    soldTo: IAddress;
    shipTo: IAddress;
    shipFrom: IAddress;
  }>();

  const initialAddressesLoaded = useRef(false);

  const { vendorConnectedAccounts } = useSelector(selectUser);
  const currentVendor = useSelector(selectCurrentVendor);
  const selectedVendorLoading = useSelector(selectVendorSelectionsLoading);
  const cartId = useSelector(selectCartId);

  useEffect(() => {
    (async () => {
      setVendors((await vendorApi.getVendors()).vendors);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (vendors) {
        const vendorMap = vendors.reduce(
          (
            acc: { [id: string]: string },
            val: { vendorId: string; vendorName: string }
          ) => {
            acc[val.vendorId] = val.vendorName;
            return acc;
          },
          {}
        );

        const connected = vendorConnectedAccounts.map(
          (connectedAccount: { vendorId: string; addresses: any[] }) => ({
            id: connectedAccount.vendorId,
            name: vendorMap[connectedAccount.vendorId],
            addresses: connectedAccount.addresses,
          })
        );

        setAddressMap(connected.reduce(addressMapReducer, {}));

        setConnectedVendors(connected);

        if (vendorId) {
          const current = connected.find(
            (vendorConnection: any) => vendorConnection.id === vendorId
          );

          dispatch(setCurrentVendor({ vendor: current ?? null }));
        }
      }
    })();
  }, [vendorConnectedAccounts, vendors, vendorId, dispatch]);

  useEffect(() => {
    if (!connectedVendors || !currentVendor) return;
    const addresses = addressMap[currentVendor.data.name];

    const {
      soldTo,
      soldTos,
      soldToMap,
      shipTo,
      shipTos,
      shipToMap,
      shipFrom,
      shipFroms,
      shipFromMap,
    } = generateAddresses(addresses, currentVendor);

    if (!initialAddressesLoaded.current) {
      initialAddressesLoaded.current = true;
      setInitialAddresses({ soldTo, shipTo, shipFrom });
    }

    setSoldToAddresses(soldTos);
    setSoldToAddressMap(soldToMap);
    setShipToAddresses(shipTos);
    setShipToAddressMap(shipToMap);
    setShipFromAddresses(shipFroms);
    setShipFromAddressMap(shipFromMap);
  }, [currentVendor, connectedVendors, addressMap]);

  const handleSelectVendor = useCallback(
    async (vendor: IConnectedVendor) => {
      setSoldToAddresses([]);
      setSoldToAddressMap({});
      setShipToAddresses([]);
      setShipToAddressMap({});
      setShipFromAddresses([]);
      setShipFromAddressMap({});
      const isSelect =
        !currentVendor || vendor.name !== currentVendor.data.name;
      const selected = isSelect ? vendor : null;
      if (!selected) {
        dispatch(setCurrentVendor({ vendor: null }));
        return;
      }

      await dispatch(setCurrentVendor({ vendor: selected }));
      setRequiresAddressUpdate(true);
    },
    [currentVendor, dispatch]
  );

  useEffect(() => {
    if (!requiresAddressUpdate || !currentVendor) return;
    const addresses = addressMap[currentVendor.data.name];

    const {
      soldTos,
      soldToMap,
      soldTo,
      shipTos,
      shipToMap,
      shipTo,
      shipFroms,
      shipFromMap,
      shipFrom,
    } = generateAddresses(addresses, currentVendor);

    setRequiresAddressUpdate(false);

    setSoldToAddresses(soldTos);
    setSoldToAddressMap(soldToMap);
    setShipToAddresses(shipTos);
    setShipToAddressMap(shipToMap);
    setShipFromAddresses(shipFroms);
    setShipFromAddressMap(shipFromMap);

    if (
      !currentVendor.soldTo ||
      !currentVendor.shipTo ||
      !currentVendor.shipFrom
    ) {
      dispatch(
        updateDeliveryInfo({
          ...(!currentVendor.soldTo && { soldTo }),
          ...(!currentVendor.shipTo && { shipTo }),
          ...(!currentVendor.shipFrom && { shipFrom }),
        })
      );
    }
  }, [requiresAddressUpdate, currentVendor, addressMap, dispatch]);

  const handleSoldToAddressChange = useCallback(
    (value: string | null) => {
      const soldTo = value ? soldToAddressMap[value] : null;
      const shipTos =
        value && currentVendor
          ? Object.values(addressMap[currentVendor.data.name][value].shipTo)
          : [];
      const shipTo = shipTos?.[0] || null;
      setShipToAddresses(shipTos);
      const shipToMap = shipTos.reduce(
        (acc: { [name: string]: IAddress }, val: IAddress) => {
          acc[val.label] = val;
          return acc;
        },
        {}
      );
      setShipToAddressMap(shipToMap);
      const shipFroms =
        soldTo && shipTo && currentVendor
          ? Object.values(
              addressMap[currentVendor.data.name][soldTo.label].shipTo[
                shipTo.label
              ].shipFrom
            )
          : [];
      const shipFrom = shipFroms?.[0] || null;
      setShipFromAddresses(shipFroms);
      const shipFromMap = shipFroms.reduce(
        (acc: { [name: string]: IAddress }, val: IAddress) => {
          acc[val.label] = val;
          return acc;
        },
        {}
      );
      setShipFromAddressMap(shipFromMap);
      dispatch(updateCurrentVendor({ soldTo, shipTo, shipFrom }));
    },
    [dispatch, currentVendor, soldToAddressMap, addressMap]
  );

  const handleShipToAddressChange = useCallback(
    (value: string | null) => {
      const shipTo = value ? shipToAddressMap[value] : null;
      const shipFroms =
        value && currentVendor && currentVendor.soldTo
          ? Object.values(
              addressMap[currentVendor.data.name][currentVendor.soldTo.label]
                .shipTo[value].shipFrom
            )
          : [];
      const shipFrom = shipFroms?.[0] || null;
      setShipFromAddresses(shipFroms);
      const shipFromMap = shipFroms.reduce(
        (acc: { [name: string]: IAddress }, val: IAddress) => {
          acc[val.label] = val;
          return acc;
        },
        {}
      );
      setShipFromAddressMap(shipFromMap);
      dispatch(updateCurrentVendor({ shipTo, shipFrom }));
    },
    [dispatch, currentVendor, shipToAddressMap, addressMap]
  );

  const handleShipFromAddressChange = useCallback(
    (value: string | null) => {
      const shipFrom = value ? shipFromAddressMap[value] : null;
      dispatch(updateCurrentVendor({ shipFrom }));
    },
    [dispatch, shipFromAddressMap]
  );

  const handleProcurementMethodChange = (event: { target: any }) => {
    dispatch(updateCurrentVendor({ procurementMethod: event.target.value }));
  };

  const handleToggleVendorSelect = useCallback(() => {
    const queryParams: { [address: string]: string } = {
      ...(cartId && { cartId }),
      ...(currentVendor?.soldTo?.id !== initialAddresses?.soldTo.id && {
        newSoldToId: currentVendor?.soldTo?.id,
      }),
      ...(currentVendor?.shipTo?.id !== initialAddresses?.shipTo.id && {
        newShipToId: currentVendor?.shipTo?.id,
      }),
      ...(currentVendor?.shipFrom?.id !== initialAddresses?.shipFrom?.id && {
        newShipFromId: currentVendor?.shipFrom?.id,
      }),
    };

    const urlSearchParams = new URLSearchParams();
    for (const param of Object.entries(queryParams)) {
      urlSearchParams.append(param[0], param[1]);
    }

    navigate(`/vendors/${currentVendor.data.id}/products?${urlSearchParams}`);
  }, [initialAddresses, currentVendor, cartId, navigate]);

  if (!connectedVendors) {
    // TODO Loading componnet
    return <div>Loading</div>;
  }

  return (
    <ConnectedVendorSelectScreen
      connectedVendors={connectedVendors}
      selectedVendor={currentVendor?.data || null}
      procurementMethod={currentVendor?.procurementMethod || "delivery"}
      soldToAddress={currentVendor?.soldTo || null}
      shipToAddress={currentVendor?.shipTo || null}
      shipFromAddress={currentVendor?.shipFrom || null}
      soldToAddresses={soldToAddresses}
      shipToAddresses={shipToAddresses}
      shipFromAddresses={shipFromAddresses}
      selectedVendorLoading={selectedVendorLoading}
      onSelectVendor={handleSelectVendor}
      onSoldToAddressChange={handleSoldToAddressChange}
      onShipToAddressChange={handleShipToAddressChange}
      onShipFromAddressChange={handleShipFromAddressChange}
      onProcurementMethodChange={handleProcurementMethodChange}
      onToggleVendorSelect={handleToggleVendorSelect}
    />
  );
};

export default ConnectedVendorSelect;
