import { IProduct } from "../../interfaces";
import axios from "../../network/axios";

const postCartItems = async (
  userId: string,
  vendorId: string,
  cartId: string,
  cartItems: {
    productName: string;
    productCode: string;
    quantity: number;
    packageType?: string;
    productInfo?: IProduct;
  }[]
) => {
  try {
    const url = `${process.env.REACT_APP_CART_API_URL}/carts/${cartId}/items?userId=${userId}&vendorId=${vendorId}`;
    const { data } = await axios.post(url, {
      items: cartItems,
      userId,
      vendorId,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (
      e?.response?.status === 400 &&
      e?.response?.data?.isValid === false &&
      e?.response?.data?.errorMessage
    ) {
      throw new Error(e.response.data.errorMessage);
    }
    throw new Error("Could not post cart items");
  }
};

export default postCartItems;
