import Provider from "react-redux/es/components/Provider";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Router from "./router";
import { CommonStore, setupStore } from "./store";
import theme from "./theme";
import { IApplication } from "./interfaces";
import { ProvideAuth } from "./auth/use-auth";
import * as FullStory from "@fullstory/browser";
import { useEffect, useRef } from "react";
import { datadogInit } from "./datadog";
import { IntercomProvider } from "react-use-intercom";
import { BrowserRouter } from "react-router-dom";

let store: CommonStore | undefined = undefined;

export function getStore() {
  return store;
}

function setStore(newStore: CommonStore) {
  if (!store) store = newStore;
  return store;
}

const Application: React.FC<IApplication> = ({
  navigation,
  routes,
  reducers = {},
}) => {
  const effectCalled = useRef<boolean>(false);

  useEffect(() => {
    if (effectCalled.current) return;
    FullStory.init({
      orgId: process.env.REACT_APP_FULLSTORY_ORG_ID ?? "",
      devMode: process.env.REACT_APP_DEV_MODE === "true",
    });
    datadogInit();
    effectCalled.current = true;
  }, []);

  return (
    <Provider store={setStore(setupStore(reducers))}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_CLIENT_ID ?? ""}
            autoBoot={true}
            autoBootProps={{
              customAttributes: {
                "Account Type": `${process.env.REACT_APP_INTERCOM_REGION}.unknown`,
                Region: process.env.REACT_APP_INTERCOM_REGION,
              },
            }}
          >
            <ProvideAuth>
              <CssBaseline>
                <BrowserRouter>
                  <Router navigation={navigation} routes={routes} />
                </BrowserRouter>
              </CssBaseline>
            </ProvideAuth>
          </IntercomProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default Application;
