import axios from "../../network/axios";

const postCart = async (userId: string, vendorId: string) => {
  try {
    const url = `${process.env.REACT_APP_CART_API_URL}/carts/?userId=${userId}&vendorId=${vendorId}`;
    const { data } = await axios.post(url, {
      userId,
      vendorId,
    });
    return data;
  } catch (e) {
    throw new Error("Could not post cart itmes");
  }
};

export default postCart;
