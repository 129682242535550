/* eslint-disable @typescript-eslint/no-empty-function */
import { Suspense } from "react";
import { styled, Theme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Navbar from "./navbar";
import Content from "./content";
import Toolbar from "./toolbar";
import Loading from "../pages/loading";
import { Outlet } from "react-router-dom";
import { ISettings } from "../settings";
import { INavigation } from "../interfaces/INavigation";
import {
  useCommonDispatch,
  useCommonSelector,
  useThemeMediaQuery,
} from "../hooks";
import { selectNavbar, selectUser, navbarCloseMobile } from "../store";

const StyledLayout = styled("div")(() => ({
  display: "flex",
}));

// TODO Move to Loading Component
const StyledLoading = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
}));
interface IStyledNavbarContainerProps {
  isMobile: boolean;
  settings: ISettings;
  open: boolean;
}

const StyledNavbarContainer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) =>
    !["settings", "isMobile"].includes(prop.toString()),
})<IStyledNavbarContainerProps>(({ theme, isMobile, settings, open }) => ({
  "& .MuiDrawer-paper": {
    minWidth: settings.navbarWidth,
    width: settings.navbarWidth,
    maxWidth: settings.navbarWidth,
    height: "100%",
    marginLeft: isMobile || open ? 0 : `-${settings.navbarWidth}px`,
    ...(!isMobile &&
      open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    ...(!isMobile &&
      !open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }),
  },
}));

interface IStyledContainerProps {
  isMobile: boolean;
  settings: ISettings;
  navbarOpen: boolean;
}

const StyledContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isMobile" && prop !== "settings" && prop !== "navbarOpen",
})<IStyledContainerProps>(({ isMobile, settings, navbarOpen }) => ({
  width:
    !isMobile && navbarOpen ? `calc(100% - ${settings.navbarWidth}px)` : "100%",
  marginLeft: !isMobile && navbarOpen ? `${settings.navbarWidth}px` : 0,
}));

interface IProps {
  navigation: INavigation;
  settings: ISettings;
}

const Layout: React.FC<IProps> = ({ navigation, settings }) => {
  const dispatch = useCommonDispatch();
  const navbar = useCommonSelector(selectNavbar);
  const user = useCommonSelector(selectUser);
  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  // TODO Loading Componnet
  if (!user) {
    return <StyledLoading>Loading...</StyledLoading>;
  }

  return (
    <StyledLayout>
      <StyledNavbarContainer
        isMobile={isMobile}
        settings={settings}
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? navbar.mobileOpen : navbar.open}
        onClose={isMobile ? () => dispatch(navbarCloseMobile()) : () => {}}
        onOpen={() => {}}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Navbar navigation={navigation} settings={settings} user={user} />
      </StyledNavbarContainer>
      <StyledContainer
        isMobile={isMobile}
        settings={settings}
        navbarOpen={navbar.open}
      >
        <Toolbar settings={settings} />
        <Content settings={settings}>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </Content>
      </StyledContainer>
    </StyledLayout>
  );
};

export default Layout;
