import { useState, useEffect } from "react";
import { styled, Theme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { NavLink, useLocation } from "react-router-dom";
import { navbarToggleMobile } from "../../../../store/reducers/ui/navbarSlice";
import useThemeMediaQuery from "../../../../hooks/useThemeMediaQuery";
import { INavigationElement } from "../../../../interfaces/INavigation";
import { useCommonDispatch } from "../../../../hooks";

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: "none",
  color: "inherit",
}));

interface IStyledListItemProps {
  nested: boolean | undefined;
  active: boolean;
}

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => !["active", "nested"].includes(prop.toString()),
})<IStyledListItemProps>(({ theme, nested, active }) => ({
  paddingLeft: nested ? 32 : 16,
  display: "flex",
  borderRadius: "0 100px 100px 0",
  "&:hover": {
    ...(!active && { backgroundColor: theme.palette.secondary.light }),
  },
  ...(active && {
    backgroundColor: theme.palette.primary.main,
  }),
  fontSize: "12px",
  gap: "4px",
}));

const StyledIcon = styled(Icon)(() => ({
  transform: "scale(0.7)",
}));

interface IProps {
  item: INavigationElement;
  nested?: boolean;
  action?: () => void;
}

const matchesURL = (itemURL: string | undefined, pathname: string) => {
  if (!itemURL) return false;
  if (itemURL === pathname) return true;
  if (itemURL === "/" && pathname !== "/") return false;
  const regex = new RegExp(itemURL + "/");
  return !!pathname.match(regex);
};

const NavItem: React.FC<IProps> = ({ item, nested, action }) => {
  const dispatch = useCommonDispatch();
  const [active, setActive] = useState(false);
  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  const { pathname } = useLocation();

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (action) {
      e.preventDefault();
      action();
    }
    if (isMobile) dispatch(navbarToggleMobile());
  };

  useEffect(() => {
    setActive(matchesURL(item.url, pathname));
  }, [setActive, pathname, item.url]);

  return (
    <StyledNavLink to={item.url || ""} onClick={(e) => onClick(e)}>
      <StyledListItem nested={nested || false} active={active}>
        {item.icon && <StyledIcon>{item.icon}</StyledIcon>}
        <ListItemText primary={item.title} disableTypography />
      </StyledListItem>
    </StyledNavLink>
  );
};

export default NavItem;
