import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Nav from "./nav";
import { ISettings } from "../../../settings";
import { INavigation } from "../../../interfaces/INavigation";
import { signOut } from "@milkmoovement/common_cognito_package";

interface IStyledNavigationProps {
  settings: ISettings;
}

const StyledNavigation = styled("div")<IStyledNavigationProps>(() => ({
  width: "calc(100% - 32px)",
}));

interface Props {
  navigation: INavigation;
  settings: ISettings;
}

function Navigation({ navigation, settings }: Props) {
  const navigate = useNavigate();
  return (
    <StyledNavigation settings={settings}>
      <List>
        {navigation.map((item) => (
          <Nav item={item} key={item.id} />
        ))}
        <Nav
          item={{
            id: "logout",
            title: "Logout",
            type: "item",
            icon: "logout",
          }}
          action={() => {
            signOut().then(() => {
              navigate("/login");
            });
          }}
        />
      </List>
    </StyledNavigation>
  );
}

export default Navigation;
