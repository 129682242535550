import { INavigation } from "@milkmoovement/mm_shop_common_web_component";

const navigation: INavigation = [
  {
    id: "shop",
    title: "Shop",
    type: "item",
    icon: "store",
    url: "/",
  },
  {
    id: "pages",
    title: "Pages",
    type: "heading",
  },
  {
    id: "orders",
    title: "Order History",
    type: "item",
    icon: "shopping_cart",
    url: "/orders",
  },
  // {
  //   id: "pickups-and-labs",
  //   title: "Pickups and Labs",
  //   type: "item",
  //   icon: "file_upload",
  //   url: "/pickups-and-labs",
  // },
  {
    id: "vendor-connections",
    title: "Vendor Connections",
    type: "item",
    icon: "link",
    url: "/vendor-connections",
  },
  // {
  //   id: "processed-fluids",
  //   title: "Processed Fluids",
  //   type: "group",
  //   icon: "coffee_maker",
  //   children: [
  //     {
  //       id: "fluid-placeholder",
  //       title: "Fluid Placeholder",
  //       type: "item",
  //       icon: "coffee_maker",
  //       url: "/fluid-placeholder",
  //     },
  //   ],
  // },
  // {
  //   id: "manage-sub-users",
  //   title: "Manage Sub Users",
  //   type: "group",
  //   icon: "group",
  //   children: [
  //     {
  //       id: "sub-user-placeholder",
  //       title: "Sub User Placeholder",
  //       type: "item",
  //       icon: "group",
  //       url: "/sub-user-placeholder",
  //     },
  //   ],
  // },
  {
    id: "support",
    title: "Support",
    type: "group",
    icon: "contact_support",
    children: [
      {
        id: "support-vendor",
        title: "Vendor",
        type: "item",
        icon: "support_agent",
        url: "/vendor-support",
      },
    ],
  },
  {
    id: "account",
    title: "Account",
    type: "heading",
  },
  // {
  //   id: "settings",
  //   title: "Settings",
  //   type: "group",
  //   icon: "settings",
  //   children: [
  //     {
  //       id: "setting-placeholder",
  //       title: "Setting Placeholder",
  //       type: "item",
  //       icon: "settings",
  //       url: "/setting-placeholder",
  //     },
  //   ],
  // },
];

export default navigation;
