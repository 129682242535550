/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  configureStore,
  combineReducers,
  Reducer,
  CombinedState,
  Action,
  AnyAction,
  ReducersMapObject,
  PreloadedState,
  StateFromReducersMapObject,
} from "@reduxjs/toolkit";
import ui from "./reducers/ui";
import user from "./reducers/user";
import order from "./reducers/order/orderSlice";

export const commonReducers = { ui, user, order };

export function setupStore<T, A extends Action = AnyAction>(
  reducers?: ReducersMapObject<T, A>,
  preloadedState?: PreloadedCombinedState<typeof commonReducers> &
    PreloadedState<State<ReducersMapObject<T, A>>>
) {
  return configureStore({
    reducer: combineReducers({
      ...commonReducers,
      ...reducers,
    }),
    preloadedState,
    devTools: process.env.REACT_APP_DEV_MODE === "true",
  });
}

export type PreloadedCombinedState<T extends ReducersMapObject> =
  PreloadedState<
    CombinedState<ReturnType<ReturnType<typeof combineReducers<T>>>>
  >;
export type State<T> = ReturnType<
  Reducer<CombinedState<StateFromReducersMapObject<T>>>
>;
export type CommonState = State<typeof commonReducers>;
export type CommonStore = ReturnType<typeof setupStore>;
export type CommonDispatch = CommonStore["dispatch"];
