import { XOR } from "utils/type-utils";
import axios from "../../network/axios";

interface IGetOrderParamsBase {
  vendorId: string;
  dateRange: { dateFrom: string; dateTo: string };
  limit?: number;
}

interface IGetOrderParamsUser extends IGetOrderParamsBase {
  userId: string;
}

interface IGetOrderParamsVendorSoldTo extends IGetOrderParamsBase {
  vendorSoldToId: string;
}

type GetOrderParams = XOR<IGetOrderParamsUser, IGetOrderParamsVendorSoldTo>;

const getOrders = async ({
  userId,
  vendorSoldToId,
  vendorId,
  //   pagination: number,
  //   search: number,
  dateRange,
  limit = 100,
}: GetOrderParams) => {
  const baseURL = `${process.env.REACT_APP_ORDER_API_URL}/orders/?vendorId=${vendorId}&limit=${limit}&dateFrom=${dateRange.dateFrom}&dateTo=${dateRange.dateTo}`;
  const userIdentifer = userId
    ? `&userId=${userId}`
    : `&vendorSoldToId=${vendorSoldToId}`;
  return (await axios.get(`${baseURL}${userIdentifer}`)).data;
};

export default getOrders;
