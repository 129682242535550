import { vendorApi } from "@milkmoovement/mm_shop_common_web_component";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommonState } from ".";

export interface IVendorFaqSlice {
  loading?: boolean;
  loadingFaq?: boolean;
  vendors: {
    [vendorId: string]: string;
  };
  selectedVendor?: {
    id: string;
    name: string;
    supportDocumentation: { faqs: any[] };
    supportInformation: any[];
  };
  error?: string;
}

const initialState: IVendorFaqSlice = {
  vendors: {},
};

export const getVendors = createAsyncThunk("vendorFAQ/getVendors", async () => {
  const vendors = await vendorApi.getVendors();
  return vendors.vendors;
});

export const setVendor = createAsyncThunk(
  "vendorFAQ/setVendor",
  async (id: string) => {
    const vendor = await vendorApi.getVendorById(id);
    return vendor;
  }
);

export const vendorFaqSlice = createSlice({
  name: "vendorFaqSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVendors.fulfilled, (state, action) => {
      const { payload } = action;
      payload.forEach((vendor: any) => {
        state.vendors[vendor.vendorId] = vendor.vendorName;
      });
      state.loading = false;
    });

    builder.addCase(getVendors.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getVendors.rejected, (state) => {
      state.error = "Error fetching Vendors. Please try again later.";
      state.loading = false;
    });
    builder.addCase(setVendor.fulfilled, (state, action) => {
      const { payload } = action;
      state.selectedVendor = {
        id: payload.vendorId,
        name: payload.vendorName,
        supportInformation: payload.supportInformation,
        supportDocumentation: payload.supportDocumentations,
      };
      state.loadingFaq = false;
    });

    builder.addCase(setVendor.pending, (state) => {
      state.loadingFaq = true;
    });

    builder.addCase(setVendor.rejected, (state) => {
      state.error = "Error fetching Vendor FAQ. Please try again later.";
      state.loadingFaq = false;
    });
  },
});

export const selectVendors = ({ vendorFaq }: CommonState) => ({
  vendors: vendorFaq.vendors,
  selectedVendor: vendorFaq.selectedVendor,
});
export const selectIsLoading = ({ vendorFaq }: CommonState) => ({
  loading: !!vendorFaq.loading,
  loadingFaq: !!vendorFaq.loadingFaq,
});

export default vendorFaqSlice.reducer;
