import { useState } from "react";
import FormWrapper from "../FormWrapper";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import Logo from "../../components/logo";
import { requestPasswordReset } from "../../cognito";
import { ClientMetadata } from "amazon-cognito-identity-js";
import {
  StyledAlert,
  StyledButton,
  StyledFormControl,
  StyledHeader,
  StyledInputLabel,
  StyledOutlinedInput,
  StyledTypography,
} from "../styles";

type IFormInputs = {
  username: string;
};

const formSchema = Joi.object({
  username: Joi.string().min(4).required(),
});

interface IForgotPasswordProps {
  onNavigateBack: () => void;
  metadata?: ClientMetadata;
}

const ForgotPassword: React.FC<IForgotPasswordProps> = ({
  onNavigateBack,
  metadata,
}) => {
  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState(false);

  const { register, handleSubmit, formState } = useForm<IFormInputs>({
    resolver: joiResolver(formSchema),
    mode: "onChange",
  });
  const { errors, isValid } = formState;

  const onSubmit = async ({ username }: IFormInputs) => {
    try {
      await requestPasswordReset(username, metadata);
      setAlert("");
      setSuccess(true);
    } catch {
      setAlert("Account not found, please contact support.");
    }
  };

  return (
    <>
      <Logo />
      <StyledHeader>Reset password</StyledHeader>
      {alert && <StyledAlert severity="error">{alert}</StyledAlert>}
      {success && (
        <StyledAlert severity="success">
          An email has been sent to you with a link to reset your password
        </StyledAlert>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        component="form"
        sx={{ width: { xs: "100%", sm: "50vw" }, maxWidth: 400 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <StyledFormControl>
          <StyledInputLabel error={!!errors.username}>
            Username/Email *
          </StyledInputLabel>
          <StyledOutlinedInput
            {...register("username")}
            label="Username/Email *"
            error={!!errors.username}
            inputProps={{ "data-testid": "input-email" }}
            disabled={success}
          />
        </StyledFormControl>
        <Box display="flex" gap={2} justifyContent="center">
          {!success ? (
            <>
              <StyledButton
                variant="outlined"
                onClick={() => {
                  onNavigateBack();
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                Reset Password
              </StyledButton>
            </>
          ) : (
            <>
              <StyledButton
                variant="contained"
                onClick={() => {
                  onNavigateBack();
                }}
              >
                Back
              </StyledButton>
            </>
          )}
        </Box>
      </Box>
      <StyledTypography>
        Don't have a valid email? Contact Support
      </StyledTypography>
    </>
  );
};

export default FormWrapper<IForgotPasswordProps>(ForgotPassword, "600px");
