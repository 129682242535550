import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledHeader = styled("div")(() => ({
  display: "flex",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  justifyContent: "center",
  fontFamily: "Roboto",
  margin: "20px 0",
}));

export const StyledAlert = styled(Alert)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: "24px",
}));

export const StyledFormControl = styled(FormControl)(() => ({
  fontSize: "16px",
}));

export const StyledInputLabel = styled(InputLabel)(() => ({
  fontSize: "16px",
}));

export const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  fontSize: "16px",
}));

export const StyledButton = styled(Button)(() => ({
  fontSize: "16px",
  padding: "8px 27px",
  textTransform: "none",
}));

export const StyledTypography = styled(Typography)(({ onClick }) => ({
  fontSize: "14px",
  lineHeight: "17px",
  ...(!!onClick && {
    textDecoration: "underline",
    cursor: "pointer",
  }),
}));
