/* eslint-disable @typescript-eslint/no-explicit-any */
import { joiResolver } from "@hookform/resolvers/joi";
import { Box, Stack, styled } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoadingOverlay } from "../../components";
import Logo from "../../components/logo";
import Joi from "joi";
import {
  authenticateUser,
  sendMFACode,
  signOut,
  clearUserPool,
} from "../../cognito";
import { ClientMetadata, CognitoUser } from "amazon-cognito-identity-js";
import { Amplify, Auth, Hub } from "aws-amplify";
import {
  StyledAlert,
  StyledButton,
  StyledTypography,
  StyledFormControl,
  StyledHeader,
  StyledInputLabel,
  StyledOutlinedInput,
} from "../styles";

const StyledPage = styled("div")(() => ({
  display: "flex",
  // backgroundColor: theme.palette.secondary.dark,
  backgroundColor: "#151B29",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "100%",
  minHeight: "100vh",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "15px !important",
  padding: "30px",
  backgroundColor: theme.palette.background.default,
  borderRadius: "10px",
  gap: "20px",
  maxWidth: "600px",
  width: "100%",
}));

type IFormInputs = {
  username: string;
  password: string;
  mfaCode?: string;
};

type Provider = {
  name: string;
  label: string;
  clientId: string;
};

const formSchema = Joi.object({
  username: Joi.string().min(4).required(),
  password: Joi.string().min(8).required(),
  mfaCode: Joi.string()
    .length(6)
    .when("MFARequired", {
      is: true,
      then: Joi.required(),
      otherwise: Joi.optional().allow(""),
    }),
});

export const setIsTimeout = (value = true) => {
  sessionStorage.setItem("mm.login.isTimeout", value ? "true" : "false");
};

interface ILoginProps {
  isLocked?: boolean;
  onSucessfulLogin: () => Promise<void>;
  onNavigateRegister?: () => void;
  onNavigateForgotPassword?: () => void;
  onNavigateLogin?: () => void;
  onLogout?: () => void;
  metadata?: ClientMetadata;
  authConfig?: any;
  internalLoginRender?: boolean;
  providers?: Provider[];
}

const Login: React.FC<ILoginProps> = ({
  isLocked,
  onNavigateRegister,
  onNavigateForgotPassword,
  onNavigateLogin,
  onSucessfulLogin,
  onLogout,
  metadata,
  authConfig,
  internalLoginRender,
  providers,
}) => {
  const [alert, setAlert] = useState("");
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [MFARequired, setMFARequired] = useState(false);
  const isTimeout = sessionStorage.getItem("mm.login.isTimeout") === "true";

  const { register, handleSubmit, formState } = useForm<IFormInputs>({
    resolver: joiResolver(formSchema),
    mode: "onChange",
  });
  const { errors, isValid } = formState;

  const defaultUsername =
    ((isTimeout || isLocked) &&
      localStorage.getItem("mm.login.currentUsername")) ||
    "";

  useEffect(() => {
    if (authConfig && providers) {
      Amplify.configure({
        aws_cognito_region:
          process.env.REACT_APP_COGNITO_USER_POOL_ID?.split("_")[0],
        aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        aws_user_pools_web_client_id: authConfig.clientId,
        oauth: {
          domain: authConfig.domain,
          scope: ["email", "openid", "profile"],
          redirectSignIn: authConfig.redirectSignIn,
          redirectSignOut: authConfig.redirectSignOut,
          responseType: "code",
        },
      });
      Hub.listen("auth", async ({ payload: { event } }) => {
        switch (event) {
          case "signIn":
            setIsTimeout(false);
            await onSucessfulLogin();
            localStorage.removeItem("mm.login.internalLogin");
            setIsLoggingIn(false);
            break;
          case "signIn_failure":
            clearUserPool();
            setAlert(
              "Error while attempting to log in, please try again or contact support."
            );
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const onFederatedSignInSubmit = async (provider: Provider) => {
    if (internalLoginRender) {
      localStorage.setItem("mm.login.internalLogin", "true");
    }
    try {
      await Auth.federatedSignIn({
        customProvider: provider.name.toLowerCase(),
      });
      localStorage.setItem("mm.login.appClientId", authConfig.clientId);
      setIsLoggingIn(true);
    } catch (err: any) {
      clearUserPool();
      setAlert(
        "Error while attempting to log in, please try again or contact support."
      );
    }
  };

  const onSubmit = async ({ username, password, mfaCode }: IFormInputs) => {
    try {
      setIsLoggingIn(true);
      setIsTimeout(false);
      setAlert("");
      localStorage.removeItem("mm.login.appClientId");
      let loginUser: CognitoUser;
      let loginMFARequired: boolean;

      if (user && mfaCode && mfaCode.length > 0) {
        ({ user: loginUser, MFARequired: loginMFARequired } = await sendMFACode(
          user,
          username,
          mfaCode
        ));
      } else {
        ({ user: loginUser, MFARequired: loginMFARequired } =
          await authenticateUser(username, password, metadata, user));
      }

      setUser(loginUser);
      if (loginMFARequired) {
        setMFARequired(true);
      } else {
        setMFARequired(false);
        await onSucessfulLogin();
      }
    } catch (err: any) {
      signOut();
      setMFARequired(false);
      setUser(undefined);
      if (err.code === "UserNotFoundException")
        setAlert("Account not found, please contact support.");
      else if (err.code === "NotAuthorizedException")
        setAlert("Invalid credentials, please try again.");
      else if (err.code === "CodeMismatchException")
        setAlert("Invalid credentials, please try again.");
      else if (err.code === "PasswordResetRequiredException")
        setAlert("Error logging in, please reset your password.");
      else
        setAlert(
          "Error while attempting to log in, please try again or contact support."
        );
    } finally {
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    const { error } = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    if (!error) return;

    history.replaceState({}, "", window.location.pathname);

    switch (error) {
      case "VerificationExpiredException":
        setAlert(
          "This verification link has expired, please contact our Support Team."
        );
    }
  }, []);

  return (
    <>
      {isLoggingIn && <LoadingOverlay />}
      <Logo />
      {internalLoginRender && onNavigateLogin ? (
        <StyledAlert
          onClick={() => {
            onNavigateLogin();
          }}
          severity="warning"
          sx={{
            width: "100%",
            maxWidth: 400,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ffeacc",
            },
          }}
        >
          This area is restricted to authorized users only. Please click here to
          return to the login page. Thank you!
        </StyledAlert>
      ) : (
        <StyledHeader>
          {isLocked ? "Your session is locked" : "Log in to your account"}
        </StyledHeader>
      )}
      {isTimeout && (
        <StyledAlert sx={{ width: "100%", maxWidth: 400 }} severity="info">
          Due to inactivity, you have been logged out. Please log in again.
        </StyledAlert>
      )}
      {isLocked && (
        <StyledAlert
          icon={<LockIcon fontSize="inherit" />}
          sx={{ width: "100%", maxWidth: 400 }}
          severity="info"
        >
          Due to inactivity, your session is locked. Enter your password to
          continue.
        </StyledAlert>
      )}
      {alert && (
        <StyledAlert
          sx={{ width: "100%", maxWidth: 400 }}
          data-testid="alert"
          severity="error"
        >
          {alert}
        </StyledAlert>
      )}
      {!internalLoginRender && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          component="form"
          sx={{ width: "100%", maxWidth: 400 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {MFARequired && (
            <>
              <StyledAlert severity="info">
                Please open your registered authenticator app and enter the
                verification code below.
              </StyledAlert>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.mfaCode}>
                  Verification Code *
                </StyledInputLabel>
                <StyledOutlinedInput
                  {...register("mfaCode")}
                  label="Verification Code *"
                  inputProps={{
                    "data-testid": "input-mfaCode",
                  }}
                  disabled={isLoggingIn}
                  error={!!errors.mfaCode}
                />
              </StyledFormControl>
            </>
          )}
          {!MFARequired && (
            <>
              <StyledFormControl
                sx={{ display: !isLocked ? "inherit" : "none" }}
              >
                <StyledInputLabel error={!!errors.username}>
                  Username/Email *
                </StyledInputLabel>
                <StyledOutlinedInput
                  {...register("username")}
                  label="Username/Email *"
                  inputProps={{
                    "data-testid": "input-username",
                  }}
                  error={!!errors.username}
                  defaultValue={defaultUsername}
                />
              </StyledFormControl>

              <StyledFormControl
                sx={{ display: isLocked ? "inherit" : "none" }}
              >
                <StyledInputLabel error={!!errors.username}>
                  Username/Email *
                </StyledInputLabel>
                <StyledOutlinedInput
                  label="Username/Email *"
                  inputProps={{
                    "data-testid": "input-username-readonly",
                  }}
                  disabled
                  error={!!errors.username}
                  defaultValue={defaultUsername}
                />
              </StyledFormControl>

              <StyledFormControl>
                <StyledInputLabel error={!!errors.password}>
                  Password *
                </StyledInputLabel>
                <StyledOutlinedInput
                  type="password"
                  {...register("password")}
                  label="Password *"
                  inputProps={{
                    "data-testid": "input-password",
                  }}
                  error={!!errors.password}
                />
              </StyledFormControl>

              <Box display="flex" gap={2} justifyContent="center">
                {onNavigateForgotPassword && (
                  <StyledTypography
                    onClick={() => {
                      onNavigateForgotPassword();
                    }}
                    style={{ color: "#0071AB" }}
                  >
                    Forgot your password?
                  </StyledTypography>
                )}
              </Box>
            </>
          )}
          <Box display="flex" gap={2} justifyContent="center">
            {onNavigateRegister && !MFARequired && (
              <StyledButton
                variant="outlined"
                onClick={() => {
                  onNavigateRegister();
                }}
              >
                Register
              </StyledButton>
            )}
            <StyledButton
              variant="contained"
              type="submit"
              disabled={!isValid || isLoggingIn}
              style={{ minWidth: isLocked ? "" : "100%" }}
            >
              {isLocked ? "Unlock" : "Log In"}
            </StyledButton>
            {onLogout && (
              <StyledButton
                variant="outlined"
                onClick={() => {
                  onLogout();
                }}
              >
                Logout
              </StyledButton>
            )}
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        paddingBottom="5%"
        sx={{ width: "100%", maxWidth: 400 }}
      >
        {providers &&
          !MFARequired &&
          (() => {
            const internalProvider = internalLoginRender
              ? providers.find((provider) =>
                  provider.label.includes("Internal Google")
                )
              : undefined;
            if (internalProvider && internalLoginRender) {
              // Render only the internal provider
              return (
                <StyledButton
                  variant="outlined"
                  data-testid="internal-sso-button"
                  style={{
                    minWidth: "100%",
                    color: "#000000de",
                    borderColor: "#3C425280",
                  }}
                  onClick={() => onFederatedSignInSubmit(internalProvider)}
                >
                  Log in with {internalProvider.label}
                </StyledButton>
              );
            } else {
              // Render all providers except internal
              return providers
                .map((provider, i) => (
                  <StyledButton
                    key={i}
                    variant="outlined"
                    data-testid={`customer-sso-button${i}`}
                    style={{
                      minWidth: "100%",
                      color: "#000000de",
                      borderColor: "#3C425280",
                    }}
                    onClick={() => onFederatedSignInSubmit(provider)}
                  >
                    Log in with {provider.label}
                  </StyledButton>
                ))
                .filter(
                  (provider) =>
                    !provider.props.children.includes("Internal Google")
                );
            }
          })()}
      </Box>
    </>
  );
};

export default (props: ILoginProps) => {
  return (
    <StyledPage>
      <Stack
        spacing={2}
        alignItems="center"
        sx={{ width: "100%", maxWidth: "600px", marginX: "15px" }}
      >
        <StyledBox>
          <Login {...props} />
        </StyledBox>
      </Stack>
    </StyledPage>
  );
};
