/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormWrapper from "../FormWrapper";
import Logo from "../../components/logo";
import getVendors from "../../api/vendors/getVendors";
import { createUser } from "../../cognito";
import {
  StyledAlert,
  StyledButton,
  StyledFormControl,
  StyledHeader,
  StyledInputLabel,
  StyledOutlinedInput,
} from "../styles";

type IFormInputs = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  organization: string;
  userType: string;
};

const formSchema = Joi.object({
  name: Joi.string().min(4).required(),
  email: Joi.string().min(4).required(),
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.ref("password"),
  organization: Joi.string().min(2).required(),
  userType: Joi.string(),
});

interface IRegisterProps {
  onNavigateBack: () => void;
}

const Register: React.FC<IRegisterProps> = ({ onNavigateBack }) => {
  const [isVendorSelected, setIsVendorSelected] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [alert, setAlert] = useState("");
  const [vendors, setVendors] = useState([]);

  const { register, handleSubmit, formState, getValues, setValue } =
    useForm<IFormInputs>({
      resolver: joiResolver(formSchema),
      mode: "onChange",
    });
  const { errors, isValid } = formState;

  useEffect(() => {
    getVendors().then(({ vendors }) => {
      setVendors(vendors);
    });
  }, []);

  const onSubmit = async ({
    email,
    password,
    name,
    userType,
    organization,
  }: IFormInputs) => {
    try {
      const orgName = isVendorSelected
        ? vendors.find((vendor) => vendor["vendorId"] === organization)?.[
            "vendorName"
          ]
        : undefined;

      await createUser(email, password, {
        name,
        email,
        "custom:user_type": userType,
        "custom:user_source": "shop-web-portal",
        "custom:org_name": isVendorSelected ? orgName : organization,
        "custom:org_id": isVendorSelected ? organization : undefined,
      });

      setIsRegistered(true);
    } catch (err: any) {
      if (err.code === "UsernameExistsException")
        setAlert("Email already registered, please login.");
      if (err.code === "UserLambdaValidationException") {
        setAlert(err.message.substring(28));
      } else if (err instanceof Error) setAlert(err.message);
      else setAlert("Unknown error occurred");
    }
  };

  return (
    <>
      <Logo />
      {!isRegistered ? (
        <>
          <StyledHeader>Register a new account</StyledHeader>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            component="form"
            sx={{ width: { xs: "100%", sm: "50vw" }, maxWidth: 600 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {alert && (
              <StyledAlert data-testid="alert" severity="error">
                {alert}
              </StyledAlert>
            )}
            <Box
              display="grid"
              gap={3}
              sx={{ gridTemplateColumns: { xs: "auto", md: "auto auto" } }}
            >
              <StyledFormControl>
                <StyledInputLabel error={!!errors.name}>
                  Name *
                </StyledInputLabel>
                <StyledOutlinedInput
                  {...register("name")}
                  label="Name *"
                  inputProps={{ "data-testid": "input-name" }}
                  error={!!errors.name}
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.email}>
                  Email *
                </StyledInputLabel>
                <StyledOutlinedInput
                  {...register("email")}
                  label="Email *"
                  inputProps={{ "data-testid": "input-email" }}
                  error={!!errors.email}
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.password}>
                  Password *
                </StyledInputLabel>
                <StyledOutlinedInput
                  type="password"
                  {...register("password")}
                  label="Password *"
                  inputProps={{ "data-testid": "input-password" }}
                  error={!!errors.password}
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.confirmPassword}>
                  Repeat Password *
                </StyledInputLabel>
                <StyledOutlinedInput
                  type="password"
                  {...register("confirmPassword")}
                  label="Repeat Password *"
                  inputProps={{ "data-testid": "input-confirm-password" }}
                  error={!!errors.confirmPassword}
                />
              </StyledFormControl>
              <StyledFormControl>
                <StyledInputLabel error={!!errors.userType}>
                  User Role *
                </StyledInputLabel>
                <Select
                  {...register("userType")}
                  label="User Role *"
                  error={!!errors.userType}
                  defaultValue={"farm-employee"}
                  inputProps={{ "data-testid": "input-role-select" }}
                  onChange={(e) => {
                    setValue("organization", "");
                    // TODO constants
                    if (e.target.value === "vendor-employee") {
                      setValue("organization", vendors[0]["vendorId"]);
                      setIsVendorSelected(true);
                    } else {
                      setIsVendorSelected(false);
                    }
                  }}
                >
                  <MenuItem value={"farm-employee"}>Farm Employee</MenuItem>
                  <MenuItem value={"farm-owner"}>Farm Owner</MenuItem>
                  <MenuItem value={"vendor-employee"}>Vendor Employee</MenuItem>
                </Select>
              </StyledFormControl>
              {isVendorSelected ? (
                <StyledFormControl>
                  <StyledInputLabel error={!!errors.organization}>
                    Organization Name *
                  </StyledInputLabel>
                  <Select
                    {...register("organization")}
                    label="Organization Name *"
                    error={!!errors.organization}
                    defaultValue={vendors[0]["vendorId"] ?? ""}
                  >
                    {vendors.map((vendor, idx) => (
                      <MenuItem
                        value={vendor["vendorId"]}
                        key={`vendor-${idx}`}
                      >
                        {vendor["vendorName"]}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              ) : (
                <StyledFormControl>
                  <StyledInputLabel error={!!errors.organization}>
                    Organization Name *
                  </StyledInputLabel>
                  <StyledOutlinedInput
                    {...register("organization")}
                    label="Organization Name *"
                    inputProps={{ "data-testid": "input-role" }}
                    error={!!errors.organization}
                  />
                </StyledFormControl>
              )}
            </Box>
            <Box display="flex" gap={2} justifyContent="center">
              <StyledButton
                variant="outlined"
                onClick={() => {
                  onNavigateBack();
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                Register
              </StyledButton>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={4}
          width={400}
        >
          <Typography variant="h6">Your account has been created.</Typography>
          <Typography variant="body1">
            You will receive a verification email in <b>{getValues("email")}</b>{" "}
            to activate your account shortly.
          </Typography>
          <Box display="flex" justifyContent="center">
            <StyledButton
              variant="contained"
              onClick={() => {
                onNavigateBack();
              }}
            >
              Login
            </StyledButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FormWrapper<IRegisterProps>(Register);
