export interface ISettings {
  navbarWidth: number;
  toolbarHeight: number;
}

const settings: ISettings = {
  navbarWidth: 280,
  toolbarHeight: 64,
};

export default settings;
