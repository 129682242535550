import { useState } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

import Nav from ".";
import { INavigationElement } from "../../../../interfaces/INavigation";

const Root = styled(List)(() => ({
  padding: 0,
  margin: 0,
  cursor: "pointer",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 16,
  display: "flex",
  borderRadius: "0 100px 100px 0",
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
  fontSize: "12px",
  gap: "4px",
}));

const StyledIcon = styled(Icon)(() => ({
  transform: "scale(0.7)",
}));

interface IProps {
  item: INavigationElement;
}

const NavGroup: React.FC<IProps> = ({ item }) => {
  const [open, setOpen] = useState(false);
  return (
    <Root>
      <StyledListItem onClick={() => setOpen(!open)}>
        {item.icon && <StyledIcon>{item.icon}</StyledIcon>}
        <ListItemText primary={item.title} disableTypography />

        <Icon>{open ? "expand_less" : "expand_more"}</Icon>
      </StyledListItem>
      <Collapse in={open}>
        {item.children?.map((child) => (
          <Nav key="id" item={child} nested />
        ))}
      </Collapse>
    </Root>
  );
};

export default NavGroup;
