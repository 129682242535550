import { default as getOrder } from "./getOrder";
import { default as getOrderDeliveryDetails } from "./getOrderDeliveryDetails";
import { default as getOrderItems } from "./getOrderItems";
import { default as getOrders } from "./getOrders";
import { default as getOrderTrackingRecords } from "./getOrderTrackingRecords";
import { default as postOrder } from "./postOrder";
import { default as verifyOrder } from "./verifyOrder";

const orderApi = {
  getOrder,
  getOrderDeliveryDetails,
  getOrderItems,
  getOrders,
  getOrderTrackingRecords,
  postOrder,
  verifyOrder,
};

export default orderApi;
