import { styled } from "@mui/material/styles";
import { INavigation } from "../../interfaces/INavigation";
import { ISettings } from "../../settings";
import Header from "./header";
import UserInfo from "./userInfo";
import Navigation from "./navigation";
import IUser from "../../interfaces/IUser";

interface IStyledNavbarProps {
  settings: ISettings;
}

const StyledNavbar = styled("div", {
  shouldForwardProp: (prop) => prop !== "settings",
})<IStyledNavbarProps>(({ theme, settings }) => ({
  position: "fixed",
  height: "100%",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  overflowY: "scroll",
  overflowX: "hidden",
  top: 0,
  bottom: 0,
  width: settings.navbarWidth,
  zIndex: 100,
}));

interface IProps {
  navigation: INavigation;
  settings: ISettings;
  user: IUser;
}

const Navbar: React.FC<IProps> = ({ navigation, settings, user }) => (
  <StyledNavbar settings={settings}>
    <Header settings={settings} />
    <UserInfo user={user} />
    <Navigation navigation={navigation} settings={settings} />
  </StyledNavbar>
);

export default Navbar;
