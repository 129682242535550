import React, { useEffect } from "react";
import {
  cognitoEvents,
  getCurrentSession,
  signOut,
} from "@milkmoovement/common_cognito_package";
import { setToken, removeToken } from "../network/axios";
import { useCommonDispatch } from "../hooks";
import { setUser, removeUser } from "../store";
import { userApi } from "../api";
import { identify, setUserVars } from "@fullstory/browser";
import { useIntercom } from "react-use-intercom";
import { datadogRum } from "@datadog/browser-rum";
import { CognitoUserSession } from "amazon-cognito-identity-js";

interface IProvideAuthProps {
  children: React.ReactNode;
}

export function ProvideAuth({ children }: IProvideAuthProps) {
  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();
  const dispatch = useCommonDispatch();

  const onSignIn = async (session: CognitoUserSession) => {
    const idToken = session.getIdToken();
    const { payload } = idToken;
    setToken(idToken.getJwtToken());
    try {
      const user = await userApi.getUser(payload["custom:user_profile_id"]);
      user.idToken = payload;

      dispatch(setUser(user));

      identify(user.userId, {
        displayName: user.idToken.name,
        email: user.idToken.email,
      });

      setUserVars({
        userName: user.idToken.name,
        userId: user.userId,
        userRole: user.idToken["custom:user_type"] ?? "Unknown",
        orgName: user.idToken["custom:org_name"] ?? "Unknown",
        orgId: user.idToken["custom:org_id"] ?? "Unknown",
        Region: process.env.REACT_APP_FULLSTORY_REGION ?? "SHOP-UNKNOWN",
      });

      datadogRum.setUser({
        id: user.userId,
        email: user.idToken.email,
        role: user.idToken["custom:user_type"] ?? "Unknown",
      });

      shutdownIntercom();

      bootIntercom({
        name: user.idToken.name,
        email: user.idToken.email,
        userId: user["custom:user_profile_id"],
        customAttributes: {
          "Account Type": `${process.env.REACT_APP_INTERCOM_REGION}.${
            user.idToken["custom:user_type"] ?? "unknown"
          }`,
          Region: process.env.REACT_APP_INTERCOM_REGION,
          "Org Name": user.idToken["custom:org_name"] ?? "unknown",
          "Org Id": user.idToken["custom:org_id"] ?? "unknown",
        },
      });
    } catch {
      await signOut();
      // eslint-disable-next-line no-restricted-globals
      if (location.pathname !== "/login") {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      throw new Error("Unable to fetch user");
    }
  };

  const onSignOut = () => {
    removeToken();
    datadogRum.clearUser();
    dispatch(removeUser());

    shutdownIntercom();
    bootIntercom({
      customAttributes: {
        "Account Type": `${process.env.REACT_APP_INTERCOM_REGION}.unknown`,
        Region: process.env.REACT_APP_INTERCOM_REGION,
      },
    });
  };

  useEffect(() => {
    cognitoEvents.addListener("signIn", onSignIn);
    cognitoEvents.addListener("signOut", onSignOut);
    (async () => {
      try {
        // Try to rerun onSignIn code if user is logged in on refresh
        onSignIn(await getCurrentSession());
      } catch {}
    })();
    return () => {
      cognitoEvents.removeListener("signIn", onSignIn);
      cognitoEvents.removeListener("signOut", onSignOut);
    };
  });

  return <>{children}</>;
}
