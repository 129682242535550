import { styled } from "@mui/material/styles";
import { ISettings } from "../../settings";

interface IStyledContentProps {
  settings: ISettings;
}

const StyledContent = styled("div", {
  shouldForwardProp: (prop) => prop !== "settings",
})<IStyledContentProps>(({ settings }) => ({
  width: "100%",
  padding: "20px",
  marginTop: settings.toolbarHeight,
  marginBottom: "100px",
}));

interface IProps {
  children: React.ReactNode;
  settings: ISettings;
}

const Content: React.FC<IProps> = ({ settings, children }) => (
  <StyledContent settings={settings}>{children}</StyledContent>
);
export default Content;
