/* istanbul ignore file */
const getVendors = async () => {
  try {
    return await (
      await fetch(`${process.env.REACT_APP_VENDOR_API_URL}/vendors`, {
        headers: {
          "X-Api-Key": process.env.REACT_APP_VENDOR_API_KEY ?? "",
        },
      })
    ).json();
  } catch {
    throw new Error("Unable to fetch vendors");
  }
};

export default getVendors;
