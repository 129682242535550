import axios from "../../network/axios";
import { IAddress } from "../../interfaces";

const patchCartItem = async (
  userId: string,
  vendorId: string,
  cartId: string,
  deliveryInfoId: string,
  deliveryInfo: {
    soldTo?: IAddress;
    shipTo?: IAddress;
    shipFrom?: IAddress;
    desiredDeliveryDate?: string;
    poNumber?: string;
    note?: string;
  }
) => {
  try {
    const url = `${process.env.REACT_APP_CART_API_URL}/carts/${cartId}/deliveryInfos/${deliveryInfoId}?userId=${userId}&vendorId=${vendorId}`;
    const { data } = await axios.patch(url, deliveryInfo);
    return data;
  } catch (e) {
    throw new Error("Could not update cart delivery info");
  }
};

export default patchCartItem;
