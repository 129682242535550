import axios from "../../network/axios";

const getVendorById = async (vendorId: string) => {
  try {
    return (
      await axios.get(
        `${process.env.REACT_APP_VENDOR_API_URL}/vendors/${vendorId}`,
        {
          headers: {
            "X-Api-Key": process.env.REACT_APP_VENDOR_API_KEY ?? "",
          },
        }
      )
    ).data;
  } catch {
    throw new Error("Unable to fetch vendor");
  }
};

export default getVendorById;
