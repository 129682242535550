import axios from "axios";

const getVendors = async () => {
  try {
    return (
      await axios.get(`${process.env.REACT_APP_VENDOR_API_URL}/vendors`, {
        headers: {
          "X-Api-Key": process.env.REACT_APP_VENDOR_API_KEY ?? "",
        },
      })
    ).data;
  } catch {
    throw new Error("Unable to fetch vendors");
  }
};

export default getVendors;
