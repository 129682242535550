import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MuiPagination from "@mui/material/Pagination";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  "@media (min-width:500px)": { flexDirection: "row" },
  width: "100%",
}));

interface IProps {
  page: number;
  pageSize: number;
  count: number;
  rowsPerPageOptions: number[];
  disabled?: boolean;
  onPageChange: (_: unknown, value: number) => void;
  onPageSizeChange: (event: { target: { value: string } }) => void;
}

const Pagination: React.FC<IProps> = ({
  page,
  pageSize,
  count,
  rowsPerPageOptions,
  disabled,
  onPageChange,
  onPageSizeChange,
}) => (
  <Root>
    {pageSize !== 0 ? (
      <MuiPagination
        aria-label="pagination"
        count={count}
        page={page}
        onChange={onPageChange}
        shape="rounded"
        color="primary"
        disabled={!!disabled}
      />
    ) : (
      <div />
    )}

    <Select
      value={pageSize.toString()}
      onChange={onPageSizeChange}
      aria-label="select"
      disabled={!!disabled}
    >
      {rowsPerPageOptions.map((num) => (
        <MenuItem key={num} value={num}>{`${num} items`}</MenuItem>
      ))}
      <MenuItem value={0}>All items</MenuItem>
    </Select>
  </Root>
);
export default Pagination;
