import DancingCow from "./cow_dance.gif";
import { Stack, styled, Typography } from "@mui/material";
import { FC } from "react";

const StyledImg = styled("img")(() => ({
  maxHeight: "500px",
  height: "50%",
  width: "auto",
}));

export const DancingCowLoader: FC<{ zIndex?: number }> = ({
  zIndex = 9999,
}) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        color: "#fff",
        position: "fixed",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex,
        bgcolor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <StyledImg src={DancingCow} alt="Dancing Cow" />
      <Typography variant="h5" color="#fff">
        Loading...
      </Typography>
    </Stack>
  );
};
