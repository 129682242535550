import axios from "../../network/axios";

const getProducts = async (
  vendorId: string,
  userId: string,
  params: Record<string, string>
) => {
  return (
    await axios.get(
      `${process.env.REACT_APP_VENDOR_API_URL}/vendors/${vendorId}/users/${userId}/products`,
      {
        headers: {
          "X-Api-Key": process.env.REACT_APP_VENDOR_API_KEY ?? "",
        },
        params,
      }
    )
  ).data;
};

export default getProducts;
