import axios from "../../network/axios";

const getOrderDeliveryDetails = async (orderId: string) => {
  try {
    const deliveryDetails = (
      await axios.get(
        `${process.env.REACT_APP_ORDER_API_URL}/orders/${orderId}/deliveryDetails`
      )
    ).data;

    return deliveryDetails;
  } catch (error) {
    console.error("Unable to fetch order delivery details", error);
    return undefined;
  }
};

export default getOrderDeliveryDetails;
