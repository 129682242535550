import { Application } from "@milkmoovement/mm_shop_common_web_component";
import navigation from "./config/navigation";
import routes from "./config/routes";
import { reducers } from "./store";

const App: React.FC = () => (
  <Application navigation={navigation} routes={routes} reducers={reducers} />
);

export default App;
