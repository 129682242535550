import { Icon } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getLogo } from "../../../../../assets/images/logos";
import IConnectedVendor from "../../../interfaces/IConnectedVendor";

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: `${isSelected ? 0 : "1px"}`,
  border: `${isSelected ? "2px" : "1px"} solid ${
    isSelected ? theme.palette.primary.main : "lightgray"
  }`,
  borderRadius: "5px",
  cursor: "pointer",
}));

const StyledSelectedIcon = styled(Icon)(() => ({
  position: "absolute",
  top: "10px",
  right: "10px",
}));

const StyledVendorImg = styled("img")(() => ({
  width: "100%",
  padding: "40px",
}));

interface IProps {
  vendor: IConnectedVendor;
  isSelected?: boolean;
  onSelectVendor: (vendor: IConnectedVendor) => void;
}

const ConnectedVendorBox: React.FC<IProps> = ({
  vendor,
  isSelected,
  onSelectVendor,
}) => (
  <Root
    aria-label="connected-vendor-box"
    key={vendor.name}
    isSelected={isSelected}
    onClick={() => onSelectVendor(vendor)}
  >
    {isSelected && (
      <StyledSelectedIcon color="primary">check_circle</StyledSelectedIcon>
    )}
    <StyledVendorImg src={getLogo(vendor.name)} alt={vendor.name} />
  </Root>
);

export default ConnectedVendorBox;
