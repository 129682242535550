import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { INavigationElement } from "../../../../interfaces/INavigation";

const Root = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: "12px",
}));

interface IProps {
  item: INavigationElement;
}

const NavHeading: React.FC<IProps> = ({ item }) => (
  <Root>
    <ListItemText primary={item.title.toUpperCase()} disableTypography />
  </Root>
);

export default NavHeading;
