import { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";

const useThemeMediaQuery = (themeCallbackFunc: (arg0: Theme) => string) => {
  const theme = useTheme();

  const query = themeCallbackFunc(theme).replace("@media ", "");

  const [matches, setMatches] = useState(getMatches(query));

  function getMatches(q: string) {
    return window.matchMedia(q).matches;
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    setMatches(getMatches(query));
    const handler = (event: { matches: boolean }) => setMatches(event.matches);
    mediaQuery.addEventListener("change", handler);

    return () => mediaQuery.removeEventListener("change", handler);
  }, [query]);

  return matches;
};

export default useThemeMediaQuery;
