import { Stack, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConnectedVendorSelectForm from "./components/ConnectedVendorSelectForm";
import IConnectedVendor from "../../interfaces/IConnectedVendor";
import IProcurementMethod from "../../interfaces/IProcurementMethod";
import { StyledContent } from "../../styles";
import { IAddress } from "@milkmoovement/mm_shop_common_web_component";

const StyledHeaderStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
  },
}));

interface IProps {
  connectedVendors: IConnectedVendor[] | null;
  selectedVendor: IConnectedVendor | null;
  procurementMethod: IProcurementMethod;
  soldToAddress: IAddress | null;
  shipToAddress: IAddress | null;
  shipFromAddress: IAddress | null;
  soldToAddresses: IAddress[];
  shipToAddresses: IAddress[];
  shipFromAddresses: IAddress[];
  selectedVendorLoading: boolean;
  onSelectVendor: (vendor: IConnectedVendor) => void;
  onSoldToAddressChange: (value: string | null) => void;
  onShipToAddressChange: (value: string | null) => void;
  onShipFromAddressChange: (value: string | null) => void;
  onProcurementMethodChange: (event: { target: any }) => void;
  onToggleVendorSelect: () => void;
}

const ConnectedVendorSelectScreen: React.FC<IProps> = ({
  connectedVendors,
  selectedVendor,
  procurementMethod,
  soldToAddress,
  shipToAddress,
  shipFromAddress,
  soldToAddresses,
  shipToAddresses,
  shipFromAddresses,
  selectedVendorLoading,
  onSelectVendor,
  onSoldToAddressChange,
  onShipToAddressChange,
  onShipFromAddressChange,
  onProcurementMethodChange,
  onToggleVendorSelect,
}) => {
  const navigate = useNavigate();

  const handleNavigateToVendorConnections = () => {
    navigate("/vendor-connections");
  };
  return (
    <StyledContent>
      <StyledHeaderStack>
        <Typography variant="h6">Shop</Typography>
        <Typography>
          Choose a vendor and select the required fields to view available
          products
        </Typography>
      </StyledHeaderStack>
      <ConnectedVendorSelectForm
        connectedVendors={connectedVendors}
        selectedVendor={selectedVendor}
        procurementMethod={procurementMethod}
        soldToAddress={soldToAddress}
        shipToAddress={shipToAddress}
        shipFromAddress={shipFromAddress}
        soldToAddresses={soldToAddresses}
        shipToAddresses={shipToAddresses}
        shipFromAddresses={shipFromAddresses}
        selectedVendorLoading={selectedVendorLoading}
        onNavigateToVendorConnections={handleNavigateToVendorConnections}
        onSelectVendor={onSelectVendor}
        onSoldToAddressChange={onSoldToAddressChange}
        onShipToAddressChange={onShipToAddressChange}
        onShipFromAddressChange={onShipFromAddressChange}
        onProcurementMethodChange={onProcurementMethodChange}
        onToggleVendorSelect={onToggleVendorSelect}
      />
    </StyledContent>
  );
};

export default ConnectedVendorSelectScreen;
