export enum VENDOR_PROCESS_FUNCTIONS {
  GET_CART = "GET_CART",
  VALIDATE_CART = "VALIDATE_CART",
  CLEAN_CART = "CLEAN_CART",
  LEAD_TIME_CART = "LEAD_TIME_CART",
}

export enum VENDOR_PROCESS_ERRORS {
  MISSING_CART_ID = "Missing required property encCartid",
}
