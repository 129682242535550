import axios from "../../network/axios";

const getOrderTrackingRecords = async (
  orderId: string,
  vendorId: string,
  userId: string
) => {
  try {
    const { trackingRecords } = (
      await axios.get(
        `${process.env.REACT_APP_ORDER_API_URL}/orders/${orderId}/trackingRecords?vendorId=${vendorId}&userId=${userId}`
      )
    ).data;

    return trackingRecords;
  } catch {
    throw new Error("Unable to fetch order records");
  }
};

export default getOrderTrackingRecords;
