import axios from "../../network/axios";
import { VENDOR_PROCESS_FUNCTIONS } from "./vendorProcessEnumerations";

const getVendors = async (
  vendorProcess: VENDOR_PROCESS_FUNCTIONS,
  vendorId: string,
  userId: string,
  body: unknown
) => {
  try {
    return (
      await axios.post(
        `${process.env.REACT_APP_VENDOR_API_URL}/vendors/${vendorId}/processes/${vendorProcess}?userId=${userId}&vendorId=${vendorId}`,
        body
      )
    ).data;
  } catch {
    throw new Error("Unable to run process");
  }
};

export default getVendors;
