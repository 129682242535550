import { ICartItem, IDeliveryInfo } from "../../interfaces";
import vendors from "../vendors";
import { VENDOR_PROCESS_FUNCTIONS } from "../vendors/vendorProcessEnumerations";

const getCartLeadTime = async (
  vendorId: string,
  userId: string,
  body: {
    deliveryInfo: IDeliveryInfo;
    items: ICartItem[];
  }
) => {
  const response = await vendors.runVendorProcess(
    VENDOR_PROCESS_FUNCTIONS.LEAD_TIME_CART,
    vendorId,
    userId,
    body
  );
  const error = new Error("Could not get lead time");
  const lastStep = response?.lastStep;
  if (
    !lastStep ||
    lastStep.functionName !== VENDOR_PROCESS_FUNCTIONS.LEAD_TIME_CART
  )
    throw error;
  const output = lastStep.output;
  if (
    !output ||
    !output.deliveryLeadTime ||
    !output.pickupLeadTime ||
    !output.workingDays ||
    !output.workingDays.length ||
    !output.plantHolidays
  )
    throw error;

  return {
    ...output,
    deliveryLeadTime: output.currentDateDisabled
      ? output.deliveryLeadTime + 1
      : output.deliveryLeadTime,
    pickupLeadTime: output.currentDateDisabled
      ? output.pickupLeadTime + 1
      : output.pickupLeadTime,
  };
};

export default getCartLeadTime;
