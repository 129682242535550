import { default as getVendors } from "./getVendors";
import { default as getVendorById } from "./getVendorById";
import { default as getProducts } from "./getProducts";
import { default as runVendorProcess } from "./runVendorProcess";
import {
  VENDOR_PROCESS_FUNCTIONS,
  VENDOR_PROCESS_ERRORS,
} from "./vendorProcessEnumerations";

const vendorApi = {
  getVendors,
  getVendorById,
  getProducts,
  runVendorProcess,
  VENDOR_PROCESS_FUNCTIONS,
  VENDOR_PROCESS_ERRORS,
};

export default vendorApi;
