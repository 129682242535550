import axios from "axios";
import axiosRetry from "axios-retry";
import { getStore } from "../../Application";
import {
  getCurrentSession,
  setIsTimeout,
  signOut,
} from "@milkmoovement/common_cognito_package";
import { setUser } from "../../store";

const instance = axios.create({
  timeout: 30000,
});

axiosRetry(instance, {
  retries: 1,
});

instance.interceptors.request.use(async (config) => {
  try {
    const session = await getCurrentSession();
    const jwt = session.getIdToken().getJwtToken();
    setToken(jwt);
    if (config.headers) config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  } catch (e) {
    await signOut();
    setIsTimeout();
    getStore()?.dispatch(setUser(null));

    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel) =>
        cancel("User unauthenticated")
      ),
    };
  }
});

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeToken = () => {
  delete instance.defaults.headers.common.Authorization;
};

export default instance;
