import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IUser from "../../../interfaces/IUser";

const StyledHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  height: "80px",
  width: "100%",
  backgroundColor: theme.palette.secondary.dark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  borderBottom: "1px solid black",
}));

const StyledEmail = styled(Typography)(() => ({
  color: "#A0A0A0",
}));

const StyledUsername = styled(Typography)(() => ({
  color: "#FFFFFF",
}));
interface IProps {
  user: IUser;
}
const UserInfo: React.FC<IProps> = ({ user }) => (
  <StyledHeader>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <StyledUsername>{user.idToken.name}</StyledUsername>
      <StyledEmail variant="subtitle2">{user.idToken.email}</StyledEmail>
    </Box>
  </StyledHeader>
);

export default UserInfo;
