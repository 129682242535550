/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { CommonState } from "../../store";
import IUser from "../../../interfaces/IUser";

export interface IUserState {
  data: IUser;
}

const initialState: IUserState = {
  data: null,
};

const userSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    setUser: (state, { payload }: { payload: IUser }) => {
      state.data = payload;
    },
    setUserInfo: (state, { payload }) => {
      state.data = {
        ...payload,
        ...(state.data?.idToken && { idToken: state.data.idToken }),
      };
    },
    setUserIdToken: (state, { payload }) => {
      state.data = { ...state.data, idToken: payload };
    },
    removeUser: (state) => {
      state.data = initialState.data;
    },
  },
});

export const selectUser = ({ user }: CommonState) => user.details.data;

export const { setUser, setUserInfo, setUserIdToken, removeUser } =
  userSlice.actions;

export default userSlice.reducer;
