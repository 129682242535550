import { Autocomplete, InputAdornment, Icon, TextField } from "@mui/material";

interface IProps {
  options: string[];
  value: string | null;
  disabled?: boolean;
  onValueChange: (value: string | null) => void;
}

const SearchSelect: React.FC<IProps> = ({
  options,
  value,
  disabled,
  onValueChange,
}) => (
  <Autocomplete
    aria-label="search-select"
    disablePortal
    options={options}
    onChange={(_, v) => onValueChange(v)}
    value={value}
    disabled={disabled}
    renderInput={(params) => {
      params.InputProps = {
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
      };
      return <TextField {...params} placeholder="Select" label="Name" />;
    }}
  />
);

export default SearchSelect;
