import axios from "../../network/axios";

const postOrder = async (vendorId: string, userId: string, cartId: string) => {
  try {
    const order = (
      await axios.post(
        `${process.env.REACT_APP_ORDER_API_URL}/orders?vendorId=${vendorId}&userId=${userId}`,
        {
          vendorId,
          userId,
          cartId,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      )
    ).data;

    return order;
  } catch {
    throw new Error("Unable to create order");
  }
};

export default postOrder;
