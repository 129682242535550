import {
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";

interface Column<RowData extends object> {
  field: string;
  title: string;
  render?: (data: RowData) => JSX.Element;
}

const getCellData = <T extends object>(row: T, column: Column<T>) => {
  const fields = column.field.split(".");
  return column.render
    ? column.render(row)
    : fields.reduce((acc: any, val: string) => {
        if (typeof acc !== "object") return "";
        return acc[val] || "";
      }, row);
};

interface IProps<RowData extends object> {
  columns: Column<RowData>[];
  data: RowData[];
}

const Table = <T extends object>({ columns, data }: IProps<T>) => (
  <TableContainer component={Paper}>
    <MuiTable>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.title}>{column.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, i) => (
          <TableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {columns.map((column) => (
              <TableCell key={`${column.title}-${i}`}>
                {getCellData<T>(row, column)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  </TableContainer>
);

export default Table;
