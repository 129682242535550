import axios from "../../network/axios";

const getCarts = async (userId: string, vendorId: string) => {
  try {
    const url = `${process.env.REACT_APP_CART_API_URL}/carts?userId=${userId}&vendorId=${vendorId}&isActive=true`;
    const { data } = await axios.get(url);
    return data.carts;
  } catch (e) {
    throw new Error("Could not get carts");
  }
};

export default getCarts;
