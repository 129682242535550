import axiosRetry from "axios-retry";
import axios from "../../network/axios";

const getUser = async (userId: string) => {
  try {
    return (
      await axios.get(`${process.env.REACT_APP_USER_API_URL}/users/${userId}`, {
        "axios-retry": {
          retries: 3,
          retryCondition: (error) =>
            axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            error?.response?.status === 404,
          retryDelay: (tries) => axiosRetry.exponentialDelay(tries + 2),
        },
      })
    ).data;
  } catch {
    throw new Error("Unable to fetch user");
  }
};

export default getUser;
